.create-code  {
    /* min-height: 100vh; */
    background-color: #e6e6e6;
   width: 100%;
   padding-top: 70px;
}

.create-code .quiz-content .codes{
    /* min-height: 100vh; */
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
} 

.create-code .quiz-content .codes form {
    border: 2px solid #e6e6e6;
    padding: 45px 25px;
    border-radius: 20px;
    display: flex;
}

.create-code .quiz-content input {
    width: 100%; 
    padding: 12px 15px;
    background-color: #f3f3f3;
    border: none;
    font-size: 1rem;
    font-weight: 400;
}
.create-code .codes .create {
    font-size: 1.7rem;
    font-weight: 600;
    margin-left: 10px;
}

.create-code .quiz-content label {
    font-size: 1.1rem;
    font-weight: 600;
} 

.create-code .quiz-content .search-button,
.create-code .quiz-content .create-button {
    height: 100%;
    margin: 35px 0 0 0;
    padding: 9px 50px;
    font-size: 1.2rem;
}

.create-code .quiz-content input:focus {
    border: none;
    outline: none;
}

.create-code .table-code {
    border-top: 2px solid #e6e6e6;
    margin-top: 30px;
}

@media (max-width:767px) {
    .create-code{
        padding: 70px 0;
        width: 100%;
    }
    .sidebar {
            width: 0;
    }
    .create-code .quiz-content .codes form {
        flex-direction: column;
}
}

@media (min-width:767px) and (max-width:1023px) {
    .create-code .quiz-content .codes form {
        flex-direction: column;
}
}



.TopRated {
    background-color: #f3f3f3;
    /* min-height: 100vh; */
    /* background: linear-gradient(to right , #ffff00 , #e6e6e6); */
    background-size: 600% 600%;
    -webkit-animation: rainbow 15s ease infinite;
    -z-animation: rainbow 15s ease infinite;
    animation: rainbow 15s ease infinite;
    
}

@-webkit-keyframes rainbow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  
  @-moz-keyframes rainbow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  
  @-o-keyframes rainbow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }
  
  @keyframes rainbow {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }



  table.table.table-striped.table-bordered.table-hover {
    border: 2px solid #ffaaaa;
    padding: 20px;
    text-align: center;
    width: 100%;
}

/* @media (max-width:767px) {
  table.table.table-striped.table-bordered.table-hover {
    border: 5px solid #e6e6e6;
    padding: 20px;
    text-align: center;
    width: 100%;
    margin-left: -7px;
}
} */

.button-85 {
  padding: 0.8em 2.6em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #f43f5e;
  /* cursor: pointer; */
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: 1.4rem;
  font-weight: 600;
}

.button-85:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f43f5e;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.btn-topRated {
    text-align: center;
}



/* .container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    animation: pulse 5s ease-in-out infinite;
    background: linear-gradient(to bottom, #1e5799, #2989d8);
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  } */


  .page-link {
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    border-radius: 5px;
    padding: 8px 12px;
  }

  .pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
  }
  
  .pagination li {
    margin: 0 10px;
  }
  
  .pagination li a {
    color: #007bff;
    font-size: 16px;
    text-decoration: none;
  }
  
  .pagination li.active a {
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
    padding: 8px 12px;
  }

  .swal-text {
    text-align: center;
    font-size: 18px;
    color: #ff0000;
  }

  button.surprize {
    background: #f43f5e;
    padding: 10px 50px;
    border-radius: 10px;
    border: 2px solid #f43f5e;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    transition: 0.3s;
  }

  button.surprize:hover {
    background-color: #f3f3f3;
    color: #000;
  }

  .div-surprize {
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
  }

  .title-surprize {
    font-size: 1.8rem;
    font-weight: 600;
    width: 100%;
  }

  .title-surprize span {
    color: #ff0000;
    margin-top: 10px;
  }

  @media (max-width:767px) {
    .box-surprize {
      width: 90%;
    }
  }
  
  
  .react-confetti-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100; 
  }





.profile-quiz {
    /* padding: 100px 0; */
    /* position: relative; */
    /* min-height: 200vh; */
    background-color: #e6e6e6;
}

.profile-quiz .profile-ground{
    min-height: 40vh;
    background-color: #e03a56;
    position: relative;
    border-radius: 0 0 10px 10px;
    width: 90%;
    margin: 0 auto;
  }
  
  .profile-quiz .profile-ground img {
    width: 100%;
    opacity: 0.1;
    height: 335px;
    border-radius: 10px 0 0 10px;
  }
  
  .profile-quiz .profile-user {
    background-color: #fff;
    /* min-height: 180vh; */
    width: 100%;
    margin: 0 auto;
    /* position: absolute; */
    /* left: 50%; */
    transform: translateX(0%);
    top: -100px;
    position: relative;
    border-radius: 10px;
  }



.bueHSC {
    color: red;
}

.cxcOiE  {
  padding: 20px 0;
}

.cxcOiE:hover {
    background-color: #FFBF00;
    color: #fff;
}

.quiz-content {
  background-color: #e6e6e6;
    position: relative;
    /* box-shadow: -5px 5px  20px #0004 ; */
    margin-bottom: 100px;
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 20px;
    border-radius: 20px;
}

 .sc-dmctIk.dSZDRP {
  border-radius: 0;
}
.sc-fEXmlR.kNUuTe.rdt_TableHeader {
  border-radius: 20px 20px  0 0 ;
}

 nav.sc-iJnaPW.gKbhqU.rdt_Pagination {
  border-radius: 0 0 20px 20px;
}
.sc-dIfARi.kDEcCH {
  border-radius: 0px 0px 0 0;
}
input.form-control {
      width: 25%;
    }



  @media (max-width:767px) {
    .profile-quiz {
        min-height: 250vh;
    }

  
  
    .content-course .main-title {
      padding: 15px 15px;
    }
    .main-info {
      margin-top: 120%;
    }
    .profile-quiz .about-course h2 {
      width: 100%;
    }
    .profile-quiz .analysis {
      margin-left: -33px;
      display: block;
    }
    .profile-quiz .analysis-bar {
      width: 100%;
      margin-bottom: 30px;
    }
    .profile-quiz .quiz-content {
      margin-top: 10%;
    }
    input.form-control {
      width: 100%;
    }
  }

  @media (min-width:767px) and (max-width:1023px) {
    .profile-quiz .quiz-content {
      margin-top: 70%;
    }
    .profile-quiz {
      min-height: 250vh;
  }
  input.form-control {
    width: 100%;
  }
  }


.navigation {
    position: absolute;
    top: 260px;
    bottom: 105px;
    right: 30px;
    width: 70px;
    border-radius: 10px;
    box-sizing: initial;
    border-left: 5px solid #e03a56;
    background: #e03a56;
    transition: width 0.5s;
    overflow-x: hidden;
  }
  @media (max-width:767px) {
 .navigation {
    top: 264px;
    bottom: -71px;
    right: 0px;
    }
 .toggle {
      transform: translateY(10px);
    }
  }
  
.navigation::-webkit-scrollbar {
  display: block;
  width: 5px;
  }
 .navigation::-webkit-scrollbar-thumb {
    background-color: wheat;
  }
  
  .navigation.active{
    width: 300px;
  }
 .navigation ul{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 5px;
    padding-top: 25px;
  }
 .navigation ul li {
    position: relative;
    list-style: none;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: 0.3s;
  }
 .navigation ul li.active {
    background: #fff;
  }
  

  
 .navigation ul li:not(:first-child):hover {
    background-color: #fff;
  }
  
 .navigation ul li b:nth-child(1) {
    position: absolute;
    bottom: -13px;
    height: 13px;
    width: 100%;
    background: #fff;
    display: none;
  }

  .navigation ul li b:nth-child(1)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 20px;
    background: #e03a56;
  }
 .navigation ul li b:nth-child(2) {
    position: absolute;
    bottom: -13px;
    height: 13px;
    width: 100%;
    background: #fff;
    display: none;
  }
.navigation ul li b:nth-child(2)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    background: #e03a56;
  }
 .navigation ul li.active b:nth-child(1),
 .navigation ul li.active b:nth-child(2) {
    display: block;
  }
    .navigation ul li:hover b:nth-child(1),
    .navigation ul li:hover b:nth-child(2) {
    display: block;
  }
  
 .navigation ul li a {
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: #fff;
  }
 .navigation ul li.active a,
 .navigation ul li:hover a{
    color: #333;
  }
 .navigation ul li a .icon{
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 70px;
    text-align: center;
    color: #fff;
  }
 .navigation ul li.active .icon,
 .navigation ul li:hover .icon{
    color: #FFBF00;
  }
 .navigation ul li a .icon i{
    font-size: 1.5em;
  }
 .navigation ul li a .title{
    position: relative;
    display: block;
    padding-left: 10px;
    height: 60px;
    line-height: 60px;
    white-space: normal;
    font-weight: 500;
  }

  .toggle{
  position: relative;
  top: 10px;
  right: -7px;
  width: 50px;
  height: 50px;
  background: #e03a56;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .toggle.active{
    background: #ff4d89;
  }
  .toggle ion-icon{
    position: absolute;
    color: #fff;
    font-size: 34px;
    display:none;
  }
  .toggle ion-icon.open,
  .toggle.active ion-icon.close{
    display:block;
  }
  .toggle ion-icon.close,
  .toggle.active ion-icon.open{
    display:none;
  }
  
  
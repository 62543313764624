

.profile-course-user {
    /* min-height: 100vh; */
}


.profile-course {
    /* min-height: 280vh; */
    background-color: #e6e6e6;
    /* position: relative; */
  }


  
  .profile-course .profile-ground{
    min-height: 40vh;
    background-color: #e03a56;
    position: relative;
    border-radius: 0 0 10px 10px;
    width: 90%;
    margin: 0 auto;
  }
  
  .profile-course .profile-ground img {
    width: 100%;
    opacity: 0.1;
    height: 335px;
    border-radius: 20px 0 0 20px;
  }
  
  .profile-course .profile-user {
    background-color: #fff;
    /* min-height: 180vh; */
    width: 100%;
    margin: 0 auto;
    /* position: absolute; */
    /* left: 50%; */
    transform: translateX(0%);
    top: -100px;
    position: relative;
    border-radius: 10px;
  }


  
.profile-course .card {
    position: relative;
    border-radius: 10px;
    box-shadow: -5px 5px  20px #0006 ;
    transition: 0.3s;
    padding: 30px 15px;
    background: #fff;
    cursor: pointer;
    margin-right: 30px;
    /* width: 100%; */
    margin-bottom: 280px; 
  }
  
  /* .profile-course .card:hover img
  {
    transform: scale(1.05);
    opacity: 1;
  } */
  
  /* .profile-course .card:hover {
    margin-top: 20px;
  } */
  
  .profile-course .card .imgbox {
    position: relative;
    width: 80%;
    height: 100%;
    transform: translateY(-80%);
    z-index: -99;
    overflow: hidden;
    border-radius: 10px;
    background-color: black;
    margin: 0 auto;
  }
  
  .profile-course .card img {
    width: 100%;
    border-radius: 10px;
    box-shadow: -5px 5px  20px #0006 ;
    transition: 0.5s;
    opacity: 0.8;
    z-index: -9999;
  }
  
  .profile-course .card .content {
    padding: 10px 8px;
    text-align: right;
    transform: translateY(-280px);
    transition: 0.3s;
  }
  
  .profile-course .card .line {
    height: 2px;
    width: 75%;
    background-color: red;
    box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);
    margin-left: auto;
  }
  
  .profile-course .card .price {
    background-color: #f43f5e;
    box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);
    padding: 10px 30px;
    border-radius: 25px;
    width: fit-content;
    font-size: 0.9rem;
    margin-left: auto;
    font-weight: 600  ;
    color: #fff;
  }
  
  .profile-course .card span {
    background-color: white;
    padding: 5px 10px;
    border-radius: 25px;
    font-weight: 600;
    color: #000;
  }
  
  /* .profile-course .card:hover > .content {
    opacity: 1;
    transform: translateY(-180px);
  } */
  
  .profile-course .card .content h2 {
    color: #7f9ead;
    /* margin-bottom: 20px; */
    font-size: 1.8rem;
    font-weight: 600;
  }
  
  .profile-course .card .btns a {
    text-decoration: none;
    color: #000;
  
    font-size: 0.8rem;
    border-radius: 25px;
    transition: 0.3s;
  }
  
  
  .profile-course .card .btns a:hover {
    color: #fff;
    background-color: #f43f5e;
    box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);
  }
  
  .profile-course .card .btns a:nth-child(2) {
    border: 2px solid #f43f5e;
    padding: 12px 40px;
  }
  
  .profile-course .card .btns a:nth-child(2):hover {
      border: 2px solid #fff;
  }
  
  .profile-course .card .btns a:nth-child(1) {
    background-color: #f43f5e;
    box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);
    color: #fff;
    padding: 14px 40px;
  }
  
  .profile-course .card .btns {
    display: flex;
    justify-content: space-between;
    margin-top: -70%;
    padding: 0 8px;
  }

    .profile-course .main {
      box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 15px;
    background-color: #e6e6e6;
    /* height: 100%; */
    border-radius: 20px;
    margin:  5px;
  }

  .profile-course .main .card {
    padding: 40px;
    background: #fff;
    width: 100%;
    border-radius: 20px;
    /* margin: 60px auto; */
    position: relative;
    border-radius: 10px;
    /* box-shadow: -5px 5px  20px #0006 ; */
    transition: 0.3s;
    padding: 30px 15px;
    background: #fff;
    cursor: pointer;
    margin-right: 30px;
    margin-bottom: 0;
    border: none;
  }

  .profile-course .details {
    text-align: right;
  }

  .profile-course .details h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: -1.7px;
  }

  .profile-course .card .images {
    overflow: hidden;
    /* width: 100%; */
    position: relative;
    background-color: #000;
    border-radius: 20px;

}
.profile-course .card img {
  width: 100%;
  border-radius: 10px;
  box-shadow: -5px 5px  20px #0006 ;
  transition: 0.7s;
  opacity: 0.9;
  z-index: 999999;
}
.profile-course .card:hover img{
  transform: scale(1.1);
  opacity: 1;
  
}

.profile-course .details .line {
  width: 75%;
  height: 3px;
  background-color: #f43f5e;
}

.profile-course .details p {
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: -0.3px;
  color: #777;
  margin-top: 25px;
  padding-bottom: 25px;
  border-bottom: 2px solid #e6e6e6;
}

.profile-course .card .price {
  background-color: #f43f5e;
  box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);
  padding: 10px 30px;
  border-radius: 25px;
  width: fit-content;
  font-size: 0.9rem;
  margin-left: 5px;
  font-weight: 600  ;
  color: #fff;
}

.profile-course .card .price-big {
  background-color: #e6e6e6;
  padding: 0px 0px;
  border-radius: 25px;
  font-weight: 600;
  color: #000;
  width: 40%;
  /* text-align: right; */
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 0 0 0 auto;
}

.profile-course  .subed {
  padding: 10px 5px;
  /* background: red; */
  width: 130px;
  margin: 0 0 0 auto;
  text-align: center;
  border: 5px solid white;
  font-size: 14px;
  background: #e6e6e663;
  font-weight: 600;
  border-radius: 15px;

  box-shadow: 0 0px 24px -9px rgb(255 0 0), 0 12px 6px -2px rgba(0,0,0,.05);
  
}
.profile-course .card .dates {
  flex-direction: column;
  margin-top: -74px;
  font-size: 14px;
  font-weight: 500;
  color: #555555;
  width: fit-content;

}

.profile-course .card .btns {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.profile-course .card .btns a {
  border:2px solid #f43f5e;
  padding: 9px 28px;
  text-decoration: none;
  color: #f43f5e;
  font-size: 14px;
  border-radius: 20px;
  transition: 0.3s;
  
}

.profile-course .card .btns a.sub {
  background-color: #f43f5e;
  color: #fff;
  box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);

}
.profile-course .card .dates .date img {
  width: 20px;
  margin-right: 8px;
}

.profile-course .card .dates .date img {
  width: 20px;
  margin-right: 8px;
}
.profile-course .card .dates .date {
  align-items: center;
  flex-direction: row-reverse;
  justify-content: left;
}

.profile-course .card .btns a.sub:hover {
  opacity: 0.91;
}

.profile-course .card .btns a.buy:hover {
  background-color: #f43f5e;
  color: #fff;
  box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);

}

@media (max-width:767px) {
  .profile-course .card .price-big {
    width: 50%;
  }
  .profile-course .card .dates {
    font-size: 14px;
    margin-top: 30px;
  }
}

@media (min-width:767px) and (max-width:1023px) {
  .profile-course .card .price-big {
    width: 50%;
  }
  .profile-course .card .dates {
    font-size: 14px;
  }
}




  @media (max-width:767px) {
  
 .profile-course .card .content {
      transform: translateY(-196px);
    }
    .profile-course .card .btns a:nth-child(1) {
         padding: 14px 20px;
    }
    .profile-course .card .btns a:nth-child(2) {
      padding: 12px 18px;
    }
  
    .profile-course .card {
      margin-bottom: 200px;
      width: 100%;
    }
    .profile-course .main-info {
      margin-top:
      400px;
    }
    .profile-course .card .content h2 {
      font-size: 1.6rem;
    }
    .profile-ground img {
      width: 100%;
      opacity: 0.6;
      border-radius: 20px 0 0 20px;
      height: 39vh;
  }
  }
    
  @media (min-width:767px) and (max-width:1023px) {
    .profile-course .card .content {
      transform: translateY(-395px);
    }
    .profile-course .card {
      margin-bottom: 400px;
    }
    .profile-course .main-info {
      margin-top: 500px;
    }
    .profile-ground img {
      width: 100%;
      opacity: 0.6;
      border-radius: 20px 0 0 20px;
      height: 39vh;
  }
    
  }



  




.register-user {
    /* min-height: 100vh; */
    background-color: #e6e6e6;
}

.register-user .box {
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 20px 20px;
    position: relative;
    border-radius: 20px;
}

.register-user form {
    background-color: #fff;
    border-radius: 20px;
    padding: 50px 30px;
}

.register-user .container {
    margin: 0 auto;
}

.register-user .input-group {
    display: flex;
    justify-content: space-between;
}

.register-user .input-group .big-input {
    width: 49%;
}

.register-user label.form-label {
    font-weight: 600;
}

.register-user  input{
    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
    background-color: #f9f9f9;
}

.register-user select {
    background-color: #f9f9f9;
}

.register-user option {
    color: #000;

}

.register-user button {
  padding: 8px 25px;
}

.register-user button:hover {
    background-color: none;
}

.register-user .error {
    color: red;
    font-weight: 500;
    font-size: 16px;
}

.register-user .box a{
  font-size: 1.1rem;
  text-decoration: none;
  color: #e02a24;
  font-weight: 600;
  transition: 0.3s;
  display: block;
}

.register-user .box a:hover {
  padding-left: 20px;
  font-weight: 800;
}

.register-user button {
    /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); */
    padding: 13px 26px;
    /* border: none; */

}

@media (max-width:767px){
    .register-user .input-group {
        display: flex;
        flex-direction: column-reverse;
    }
    .register-user .input-group .big-input {
        width: 100%;
    }
}

.register-button {
    text-align: center;
    width: 100%;
}

.login-btn, 
.register-btn {
    all: unset;
    width: 190px;
    height: 40px;
    font-size: 1.3rem;
    font-weight: 600;
    background: transparent;
    border: none;
    position: relative;
    color: #f0f0f0;
    cursor: pointer;
    z-index: 1;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: 0 auto;
  }
  
  .login-btn::after,
  .login-btn::before ,
  .register-btn::after,
  .register-btn::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -99999;
    transition: all .4s;
  }
  
  .login-btn::before ,
  .register-btn::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    transition: 0.5s;
    box-shadow: inset 0 0 50px rgba(0 , 0 , 0 ,0.3);   
     border-radius: 10px;
  }

  .login-btn::before {
    background-color: #61dafb;

  }
  .register-btn::before {
    background-color: #f43f5e;

  }
  
  .login-btn::after ,
  .register-btn::after { 
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
  }
  
  .login-btn:hover::before ,
  .register-btn:hover::before {
    transform: translate(5%, 20%);
    width: 110%;
    height: 110%;
  }
  
  .login-btn:hover::after ,
  .register-btn:hover::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }
  
  .login-btn:active::after ,
  .register-btn:active::after {
    transition: 0s;
    transform: translate(0, 5%);
  }
.grade  {

    min-height: 80vh;
    /* margin: 220px 0; */

  }

  .grades {
    /* background-color: #e6e6e6; */
    /* z-index: -111; */
  }
  
    
  

  
  /* .grade .card:hover img
  {
    transform: scale(1.05);
    opacity: 1;

  }
   */
  /* .grade .card:hover {
    margin-top: 20px;
  }
   */
  .grade .card .imgbox {
    position: relative;
    width: 80%;
    height: 100%;
    transform: translateY(-80%);
    z-index: -99;
    overflow: hidden;
    border-radius: 10px;
    background-color: black;
    margin: 0 auto;
  }
  
  
  .grade .card .content {
    padding: 10px 8px;
    text-align: right;
    transform: translateY(-280px);
    transition: 0.3s;
  }
  
  .grade .card .line {
    height: 2px;
    width: 75%;
    background-color: red;
    box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);
    margin-left: auto;
  }
  

  
 

  .grade .card .content h2 {
    color: #7f9ead;

    font-size: 1.8rem;
    font-weight: 600;
  }
  

  
  
  @media (max-width:767px) {
    .grades .main-title {
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }
    .grades .grade {
      /* margin-top: 200px; */
    }
  
    .grades .grade .card .content {
      transform: translateY(-250px);
    }
    .grade .card .btns a:nth-child(1) {
         /* padding: 14px 26px; */
    }
    .grade .card .btns a:nth-child(2) {
      /* padding: 12px 18px; */
    }
  
    .grade .card {
      margin-bottom: 200px;
    }
  }

  @media (min-width:767px) and (max-width:1023px)  {
    .grades .grade {
      margin-top: 200px;
    }
    .grades .grade .card .content {
      transform: translateY(-230px);
    }
    .grade .card {
      margin-bottom: 400px;
    }
    .grade .card .btns a:nth-child(1) {
      padding: 14px 26px;
 }
 .grade .card .btns a:nth-child(2) {
   padding: 12px 18px;
 }
 .grade .card {
  margin-bottom: 200px;
}
  }
  



/* 
    .grade .box {
    position: relative;
    overflow: hidden;
    background-color: #e6e6e6;
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    border-radius:20px ;
    width: 500px;
   }

  .grade .image {
    width: 100%;
    padding: 20px;

  }
  .grade .image img{
    width: 100%;
    border-radius:20px ;
  }
  .grade .info{
     width: 90%; 
    position: absolute;
    top: 30%;
    background-color: #fff  ;
    transition: 0.3s;
    padding: 10px;
    text-align: right;
  }
  .grade .info h2 {
    color: #444444;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .grade .box:hover .info {

      
  }  */

  h2 {
    margin-top: 0;
  }

  .grade .main {
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 15px;
    background-color: #e6e6e6;
    height: 100%;
    border-radius: 20px;
    margin:  5px;
   
  }

  @media (max-width:767px) {
    .grade .main {
     padding:  15px ;
    }
  }
  
    .grade .card {
    padding: 40px;
    background: #fff;
    width: 100%;
    border-radius: 20px;
    /* margin: 60px auto; */
    position: relative;
    border-radius: 10px;
    /* box-shadow: -5px 5px  20px #0006 ; */
    transition: 0.3s;
    padding: 30px 15px;
    background: #fff;
    cursor: pointer;
    margin-right: 30px;
    margin-bottom: 0;
    border: none;
    height: 100%;


  }
  
  .highlight {
    font-weight: bold;
    color: #294;
  }




  .grade .container {
    max-width: 1500px;
  }
  .grade .details {
    text-align: right;
  }
  .grade .details h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    letter-spacing: -1.7px;
  }
  .grade .card .images {
      overflow: hidden;
      /* width: 100%; */
      position: relative;
      background-color: #000;
      border-radius: 20px;
      height: 100%;


  }
  .grade .card img {
    width: 100%;
    border-radius: 10px;
    box-shadow: -5px 5px  20px #0006 ;
    transition: 0.7s;
    opacity: 0.8;
    z-index: 999999;
    height: 100%;

    
  }
  .grade .card:hover img{
    transform: scale(1.1);
    opacity: 1;
    
  }

  .grade .details .line {
    width: 75%;
    height: 3px;
    background-color: #f43f5e;
  }

  .grade .details p {
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: -0.3px;
    color: #777;
    margin-top: 25px;
    padding-bottom: 25px;
    border-bottom: 2px solid #e6e6e6;
  }

  .grade .card .price {
    background-color: #f43f5e;
    box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);
    padding: 10px 30px;
    border-radius: 25px;
    width: fit-content;
    font-size: 0.9rem;
    margin-left: 5px;
    font-weight: 600  ;
    color: #fff;
  }

  .grade .card .price-big {
    background-color: #e6e6e6;
    padding: 0px 0px;
    border-radius: 25px;
    font-weight: 600;
    color: #000;
    width: 100%;
    /* text-align: right; */
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 0 0 0 auto;
    justify-content: space-between;
  }

  .grade .dateandbox{
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
  }

  @media (max-width:767px) {
    .grade .dateandbox {
      flex-direction: column;
    }
  }

  .grade .subed {
    background: #e6e6e663;
    border: 5px solid white;
    box-shadow: 0 0px 24px -9px rgb(255 0 0), 0 12px 6px -2px rgba(0,0,0,.05);
    font-size: 14px;
    font-weight: 600;
    padding: 10px 5px;
    text-align: center;
    width: 130px;
    border-radius: 15px;
}

  .grade .card .dates {
    flex-direction: column;
    /* margin-top: -47px; */
    font-size: 17px;
    font-weight: 500;
    color: #555555;

  }

  .grade .card .btns {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    /* padding: 0 10px; */
  }

  .grade .card .btns a {
    border:2px solid #f43f5e;
    padding: 9px 28px;
    text-decoration: none;
    color: #f43f5e;
    font-size: 14px;
    border-radius: 20px;
    transition: 0.3s;
    text-align: center;
  }
  
  @media  (max-width:767px) {
    .grade .card .btns a {
      padding: 9px 18px;

    }

  }

  .grade .card .btns a.sub {
    background-color: #f43f5e;
    color: #fff;
    box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);

  }
  .grade .card .dates .date img {
    width: 20px;
    margin-right: 8px;
  }
  .grade .card .dates .date {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: left;
  }

  .grade .card .btns a.sub:hover {
    opacity: 0.91;
  }

  .grade .card .btns a.buy:hover {
    background-color: #f43f5e;
    color: #fff;
    box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);

  }

  @media (max-width:767px) {
    .grade .card .price-big {
      width: 100%;
    }
    .grade .card .dates {
      font-size: 14px;
      margin-top: 15px;
    }
  }

  @media (min-width:767px) and (max-width:1023px) {
    .grade .card .price-big {
      width: 105%;
    }
    .grade .dateandbox {
      flex-direction: column;
    }
    .grade .card .dates {
      font-size: 14px;
      margin-top: 20px;
    }
  }

    .no-courses {
    background-color: #f9f9f9;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

      .no-courses  h2 {
        text-shadow: 0px 0px 40px #e02a24;

        color: #d53752;
    font-size: 55px;
    font-weight: 700;
      }

      .main-loading {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e6e6e6;

      }

      .loading-course {
    
        background-color: #f43f5e;

        color: #fff;
        width: 40%;
        margin: 0 auto;
        padding: 50px 0;
        border-radius: 15px;
        text-align: center;
        opacity: 0.9;

      }

      @media (max-width:767px) {
        .loading-course {
          width: 90%;
          margin: 0 auto;
        }
      }

      
      .swal-overlay.swal-overlay--show-modal {
        z-index: 99999999;
      }

      .button-52 {
        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 13px 20px 13px;
        outline: 0;
        border: 2px solid black;
        cursor: pointer;
        position: relative;
        background-color: rgba(0, 0, 0, 0);
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        border-radius: 7px;
        color: #fff;
      }
      
      .button-52:after {
        content: "";
        background-color: #f43f5e;
        width: 100%;
        z-index: -1;
        position: absolute;
        height: 100%;
        top: 7px;
        left: 7px;
        transition: 0.2s;
        border-radius: 5px;

      }
      
      .button-52:hover:after {
        top: 0px;
        left: 0px;
        
      }
      .button-52:hover {
        border: 2px solid #f43f5e;

      }
      
      @media (min-width: 768px) {
        .button-52 {
          padding: 13px 50px 13px;
        }
      }

      .button-courses {
        text-align: center;
        margin-bottom: 30px;
      }
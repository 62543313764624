

.admin-year {
    min-height: 100vh;
    background-color: #e6e6e6;
   width: 100%;
   padding: 70px 0;
}


.admin-year .box {
    background-color: #fff;
    justify-content: space-between;
    border-radius: 20px;
}

.admin-year i.fa-solid.fa-calendar-week,
.admin-year i.fa-solid.fa-circle-question,
.admin-year i.fa-solid.fa-users, 
.admin-year i.fa-solid.fa-list-check,
.admin-year i.fab.fa-youtube {
    color: white;
    /* padding: 25px; */
    font-size: 2rem;
    border-radius: 50%;
    /* width: 15%; */
    /* height: 81%; */
    height: 75px;
    width: 75px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-year i.fa-solid.fa-calendar-week {
    background-color: #e03a56;
}
.admin-year i.fa-solid.fa-circle-question {
    background-color: rgb(153, 255, 0);
}

.admin-year i.fa-solid.fa-users {
    background-color: #ffb222;
}
.admin-year i.fa-solid.fa-list-check {
    background-color: #3b82f6;
}
.admin-year i.fab.fa-youtube {
    background-color: #ff0000;
}

.admin-year .details {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.admin-year .details span:first-child,
.admin-year .details span:last-child {
    font-weight: 600;
    font-size: 1.3rem;
} 

.admin-year .details span:first-child {
    color: #777777;
}

.admin-year .details span:last-child {
    color: black;
    font-size: 1.5rem;
    font-size: 1.5rem;
}


@media (max-width:767px) {
    .admin-year {
        width: 100%;
    }
}


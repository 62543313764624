.landing {
    background-image: linear-gradient( 90deg, #e02a24 -200% , #FFFFFF 100%)  ;
    /* background-image: linear-gradient( 135deg, #EB5757 15% , #000000 100%); */
    /* background: url(/public/images/Coal.jpg); */
    /* background: url(/public/images/bg.3b55416e926d51a05f75.png); */
    /* background-color: #e21d48; */
    min-height: 100vh;
    /* background-size: cover; */
    background-position: center top;
  }
  
 .landing .animate-image {
    width: 60%;
    /* border-radius: 130px 100px 150px 0px; */
    /* margin-top: 95px;d */
  }

  img.ramadan {
    width: 8%;
    position: absolute;
    left: 8%;
    top: 83px;
        animation: ramadan-lantern 2s ease-in-out infinite;
  }

  @media (max-width:767px) {
    img.ramadan {
      width: 30%;
      position: absolute;
      left: -4%;
      top: 67px;
            /* animation: ramadan-lantern 2s ease-in-out infinite; */
    }
  }

  @media (min-width:767px) and (max-width:1023px) {
    img.ramadan {
      width: 15%;
      position: absolute;
      left: 2%;
      top: 20%;
      /* animation: ramadan-lantern 2s ease-in-out infinite; */
    }
  }


  
  /* @keyframes ramadan-lantern {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(2deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-2deg);
    }
    100% {
      transform: rotate(0deg);
    }
  } */

  @media (max-width:767px) {
    .landing .animate-image {
      width: 70%;
    
    }
    .landing .image
    {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    }
  
  .landing .type {
    font-size: 3.5rem;
    font-weight: bold;
    text-align: right;
   
  }
  
  .landing .text {
    margin-left: 180px;
  }
  
  .landing h3 {
    text-align: center;
    margin-top: 30px;
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 2;
    color: #d53752;
    -webkit-text-stroke-width: 1px;
    
  }
  
  .landing h3 span {
    width: 100%;
    display: block;
  color: #3b82f6;
  font-weight: bold;
  }
  
  
  .landing .sub,
  .landing .sub-2 {
    border-radius: 10px;
    width: fit-content;
    font-weight: bold;
    line-height: 3em;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.5s;
    margin: 25px auto 0 auto;
    text-decoration: none;
    padding: 0px 30px;
    font-size: 1.1rem;
    -webkit-text-stroke-width: 0.5px;
    letter-spacing: 0.5px;
  }
  
  .landing .sub {
    color: #e02a24;
    border: 0.1em solid #e02a24;
  }
  
  .landing .sub-2 {
    color: #3b82f6;
    border: 0.1em solid #3b82f6;
  }
  
  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .animate-image {
    animation-name: slide-in;
    animation-duration: 1.5s;
  }

  .landing .sub span ,
  .landing .sub-2 span {
    position: absolute;
    width: 25%;
    height: 100%;
    transform: translateY(150%);
    border-radius: 50%;
    left: calc((var(--n) - 1) * 25%);
    transition: 0.5s;
    transition-delay: calc((var(--n) - 1) * 0.1s);
    z-index: -1;
  }
  
  .landing .sub span {
    background-image: linear-gradient( 135deg, #e02a24 10% , #FFFFFF 100%)  ;
  }
  
  .landing .sub-2 span {
    background-image: linear-gradient( 135deg, #B2FEFA -20% , #3b82f6 100%)  ;
  }
  
  .landing .sub-2:hover ,
  .landing .sub:hover {
    color:#F2F5F8;
    border: none ;
  }
  .landing .sub-2:hover span ,
  .landing .sub:hover span {
    transform: translateY(0) scale(2);
  }
  .landing .sub span:nth-child(1),
  .landing .sub-2 span:nth-child(1) {--n: 1;
  }
  .landing .sub span:nth-child(2) ,
  .landing .sub-2 span:nth-child(2) {--n: 2;
  }
  .landing .sub span:nth-child(3),
  .landing .sub-2 span:nth-child(3) {--n: 3;
  }
  .landing .sub span:nth-child(4) ,
  .landing .sub-2 span:nth-child(4) {--n: 4;
  }
  
  @media (max-width:767px) {
    .landing {
      padding: 10px 0 60px 0;
    }
    .landing .text {
      margin-left: 0;
    }
    .landing .type {
      text-align: center;
      margin-top: 10px;
    }
    .landing .animate-image {
      margin-top: 35px;
    }
  }
  @media (min-width:767px) and (max-width:1023px) {
    .landing .image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .landing .animate-image {
      margin-top: 30px;
      width: 50%;
    }
    .landing .type {
      text-align: center;
      margin-top: 10px;
    }
    .landing .text {
      margin-left: 0;
    }
    .landing {
      padding: 10px 0 60px 0;
    }
  }

  
  /* .feature {
    background-image: linear-gradient( 135deg, #f43f5e 10% , #eeeeee 100%)  ;
    min-height: 50vh;
    background-size: cover;
    z-index: -999;
    background-position: center top;
    position: relative;
  } */
  
  .feature{
    position: relative;
    /* background-color: #f3f3f3; */
    min-height: 90vh;
  }
  
  .feature img{
  width: 100%;
  transition: 0.5s;
  }
  
  .feature .box:hover img {
    transform: scale(1.1);
  }
  
  .feature .box {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
    border-radius: 20px;
  }
  
  .feature .box::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    /* background-color: #000; */
    top: 0;
    left: 0;
    opacity: 0.2;
  }
  
  /* .feature img {
    width: 100%;
    opacity: 0.5;
    position: absolute;
  } */
  
  .feature .boxl-1, 
  .feature .boxl-2, 
  .feature .boxl-3 {
    position: relative;
  }
  
  .feature .boxl-1:hover h2,
  .feature .boxl-3:hover h2{
    transform: rotate(0deg);
  }
  
  .feature .boxl-1 h2,
  .feature .boxl-2 h2,
  .feature .boxl-3 h2 {
    font-size: 1.5rem;
    text-align: center;
    transform: rotate(-12deg);
    transition: 0.3s;
    color: #fff;
    font-weight: bold;
    padding: 7px 0;
    margin-top: -15px;
    border-radius: 20px;
  }

  @media (max-width:767px) {
    .feature .boxl-1 h2,
    .feature .boxl-2 h2,
    .feature .boxl-3 h2 {
      font-size: 1.3rem;
    }
  }
  
  .feature .boxl-2 h2 {
    transform: rotate(0deg);
  }
  
  .feature .boxl-1 h2 {
    background-color: #e02a24;
  }
  
  .feature .boxl-2 h2{
    background-color: #eab308;
  }
  .feature .boxl-2 h2{
    background-color: #3b82f6;
  }
  
  .feature .boxl-3 h2{
    background-color: #eab308;
  }
  
  @media (max-width:767px) {
      .feature {
        padding-bottom: 50px;
      }
      .feature .boxl-1, 
  .feature .boxl-2, 
  .feature .boxl-3 {
    margin-bottom: 40px;
  }
  }

  @media (min-width:767px) and (max-width:1023px)  {
    .feature .boxl-1, 
    .feature .boxl-2, 
    .feature .boxl-3 {
      margin-bottom: 80px;
    }
  }
  
  
  .waves {
    position: relative;
    width: 100%;
    height: 45vh;
    background-color: #3586ff;
    overflow: hidden;
  }
  
  .waves .wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background-image: url(/public/images/wave.png);
    background-size: 1000px 100px;
  }
  
  .waves .wave.wave-1 {
    animation: animat 30s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
  }
  
  .waves .wave.wave-2 {
    animation: animat2 15s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
  }
  
  .waves .wave.wave-3 {
    animation: animat 30s linear infinite;
    z-index: 998;
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15px;
  }
  
  
  .waves .wave.wave-4 {
    animation: animat2 2s linear infinite;
    z-index: 997;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20px;
  }
  
  @keyframes animat {  
    0%{
       background-position-x: 0;
    }
    100% {
          background-position-x: 1000px;
    }
  }
  
  @keyframes animat2 {
    0%{
       background-position-x: 0;
    }
    100% {
          background-position-x: -1000px;
    }
  }
  
  .waves h2 {
     font-size: 3rem;
      font-weight: bold;
      color: #fff;
      text-align: right;
      margin: 30px 55px 0 0;
      text-shadow: 0 0 0 #000;
/* text-shadow: -50px 20px 10px #777;   */
  }
  
  @media (max-width:767px) {
    .main-courses h2 {
      text-align: center;
      margin-right: 0;
      font-size: 2rem;
    }
  }
  
  .main-courses .edu{
  text-align: right;
      margin: 30px 55px 0 0;
      font-size: 2.4rem;
      font-weight: 600;
      letter-spacing: -1px;
  }
  
  .main-courses .edu span {
    color: #e02a24
  }
  
  @media (max-width:767px) {
    .main-courses .edu {
      text-align: center;
      margin-right: 0;
    }
  }
  
  
  .main-courses .courses {
    /* min-height: 100vh; */
    margin-top: 100px;
    margin-bottom: 50px;
    min-height: 65vh;
  } 
  
  .courses .card {
    position: relative;
    width: 350px;
    height: 190px;
    background-color: #fff;
    transition: 0.5s;
    border-radius: 10px;
    border: none;
  }
  
  .courses .card:hover {
    height: 543px; 
  }
  
  .courses .card .lines {
    position: absolute;
    inset: 0;
    background-color: #fff;
    overflow: hidden;
    box-shadow: -5px 5px  20px #0006 ;
    border-radius: 10px;
  }
  
  
  .courses .card .lines::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 600px;
    height: 120px;
    background:linear-gradient(transparent, #3b82f6 , #3b82f6 , #3b82f6 , transparent);
    animation: card 4s linear infinite;
    border-radius: 10px;
  }
  
  @keyframes card {
      0%{
        transform: translate(-50% , -50%) rotate(0deg);
  
      }
      100%{
        transform: translate(-50% , -50%) rotate(360deg);
  
      }
  }
  
  .courses .card .lines::after {
    content: "";
    position: absolute;
    background-color: #fff;
    inset: 3px;
    border-radius: 10px;
  }
  
  .courses .card  .imgBx {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      width: 220px;
      height: 165px;
      background: #000;
      transition: 0.5s;
      z-index: 10;
      overflow: hidden;
      border-radius: 10px;
      
  }
  
  .courses .card:hover .imgBx {
    width: 250px;
    height: 250px;
  }
  
  .courses .card .imgBx::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 150px;
    transform: translate(-50% , -50%);
      background:linear-gradient(transparent, #e02a24 , #e02a24 , #e02a24 , transparent);
      animation: card2 6s linear infinite;
      border-radius: 10px;
  } 
  
  @keyframes card2 {
       0%{
        transform: translate(-50% , -50%) rotate(360deg);
      }
      100%{
        transform: translate(-50% , -50%) rotate(0deg);
      }
  }
  
  .courses .card .imgBx::after {
    content: "";
    position: absolute;
    background-color: #292929;
    inset: 3px;
    border-radius: 10px;
  }
  
  .courses .card .imgBx img {
    position: absolute;
    top: 5px;
    left: 5px;
      z-index: 1;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
      /* filter: grayscale(1); */
      border-radius: 10px;
  }
  
  .courses .card  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .courses .card  .content .details {
    padding: 25px;
    text-align: center;
    width: 100%;
    transition: 0.5s;
    transform: translateY(183px);
  }
  
  .courses .card:hover  .content .details {
    transform: translateY(95px);
  }
  
  .courses .card  .content .details h2{
      font-size: 2.1rem;
      font-weight: 600;
      margin-bottom: 17px;
  }
  
  .courses .card  .content .details h2 span{
      color: #e02a24;
  }
  
  .courses .card  .content .details .data {
      /* margin: 15px 0 20px 0; */
  }
  
  .courses .card  .content .details .data .data-content{
      display: block;
      margin-bottom: 8px;
      text-align: right;
      color: #777;
      font-weight: 600;
      font-size: 1rem;
  }
  
  .courses .card  .content .details .data .data-content span {
    color: #e02a24;
    font-size: 1.3rem;
  }

  .courses .card  .content .actionBtn {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  
  }
  
  .courses .card  .content .actionBtn a {
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 20px;
    border: 1px solid #e43b58;
      transition: 0.3s;
      font-weight: 500;
  }

  @media (max-width:767px) {
    .courses .card  .content .actionBtn a {
      padding: 10px 15px;
    }
  }
  
.courses .card  .content .actionBtn a.first{
    background-color: #e43b58;
      color: white;
  }
  
.courses .card  .content .actionBtn a.last{
    color: #000;
  }
  .courses .card  .content .actionBtn a.last:hover{
    background-color: #e43b58;
    color: #fff;
  }
  
  
  @media (max-width:767px) {
    .courses .card {
      margin-bottom: 100px;
      width: 100%;
    }
  }
  

  @media (min-width:767px) and (max-width:1023px) {
    .courses .card {
      margin: 0 auto 100px;     
    }
    .courses {
      text-align: center;
    }
  }
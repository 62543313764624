
.video {
    background-color: #e6e6e6;
    /* width: 50%; */
    padding: 100px 0;
}
.video-play {
    width: 100%;
    position: relative;

    margin: 0 auto;
    /* border: 10px solid #000; */
    /* min-height: 69vh; */
}

.secure-id {
  position: absolute;
  font-size: 2.5rem;
  color: red;
  z-index: 99999999;
  top:10%;
  font-weight: 600;
  left: 8%;
  opacity: 0.3;
}

.yt-cove {
  display: block;
  position: absolute;
  z-index: 999999999999;
  width: 260px;
  height: 55px;
  bottom: 50px;
  right: 10px;
  /* background-color: #fff; */
}

.yt-cov1 {
  display: block;
  position: absolute;
  z-index: 999999999999;
  width: 260px;
  height: 80px;
  bottom: 17px;
  right: 0px;
  /* background-color: red; */
}

@media (max-width:767px) {
  .yt-cove {
    display: block;
    position: absolute;
    z-index: 999999999999;
    width: 260px;
    height: 55px;
    bottom: 50px;
    right: 10px;
    /* background-color: #fff; */
  }
  .yt-cov1 {
    display: block;
    position: absolute;
    z-index: 999999999999;
    width: 260px;
    height: 60px;
    bottom: 0px;
    left: 10px;
    /* background-color: #fff; */
    width: 100%;
  }
}


.secur-id {
  position: absolute;
  bottom: 10%;
  color: red;
  font-size: 0.8rem;
  /* top: 9px; */
  left: 75%;
  font-weight: bolder;
  opacity: 0.3;
}




@media (max-width:767px) {
  .video-play {
    width: 100%;
  }
  .video .video-iframe iframe  {
    min-height: 0;
  }
  .secur-id {
    position: absolute;
    bottom: 10%;
    color: red;
    font-size: 1rem;
    /* top: 9px; */
    left: 30%;
    font-weight: bolder;
    opacity: 0.3;
  }
}
.video .video-iframe iframe {
  height: 100%;
  position: relative;
}

.rh5v-Volume_icon,
.rh5v-PlayPause_icon,
.rh5v-Fullscreen_icon {
    padding: 0;
}

.video .video-iframe iframe {
    width: 100%;  
    min-height: 100vh;
}



.yt-cover{
  
  position: absolute;
  top: 0;
  bottom: 15%;
  right: 0;
  left: 0;
  z-index: 1000;
  border-radius: 11px 0 464px 0;
  /* background: red; */
}



@media (max-width:767px) {
  .yt-cover {
    border-radius: 0px;
    position: absolute;
    top: 0;
    bottom: 75%;
    right: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    /* background: red; */
  }


}

@media (min-width:767px) and (max-width:1023px) {
  .yt-cover {
    position: absolute;
    top: 0;
    bottom: 70%;
    right: 0;
    left: 0;
    z-index: 1000;
    border-radius: 0px;
    width: 100%;
    /* background-color: red; */
  }
  .yt-cov1, 
  .yt-cov2 {
    position: absolute;
    z-index: 999999999999;
    width: 260px;
    height: 60px;
    bottom: 10px;
    /* background-color: red; */
  }
  .yt-cov1 {
    left: 5px;
  }
  .yt-cov2 {
    right: 0px;
  }
}

.video-iframe {
  user-select: none;
  /* background-image: url('black-screen-image.jpg'); */
  background-color: black;
  background-size: cover;
  background-position: center;
}




.button-50 {
  appearance: button;
  background-color: #f43f5e;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0, #000 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  /* font-family: ITCAvantGardeStd-Bk,Arial,sans-serif; */
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 39px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 40px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  /* white-space: nowrap; */
}

.button-50:focus {
  text-decoration: none;
}

.button-50:hover {
  text-decoration: none;
}

.button-50:active {
  box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
  outline: 0;
}

.button-50:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .button-50 {
    padding: 12px 50px;
  }
}



.video .seek {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}



.video .seek button {
  background-color: #333;
  transition: 0.3s;
  border: none;
  padding: 10px 15px;
  margin-left: 20px;
  margin-right: 20px;
  color: #fff;
  border-radius: 5px;

}
.video .seek button:hover {
  background-color: #00b2ff;
}






.profile {
    /* min-height: 180vh; */
    background-color: #e9e9e9;
    /* position: relative; */
  }


  
  .profile .profile-ground{
    min-height: 40vh;
    background-color: #e03a56;
    position: relative;
    border-radius: 0 0 10px 10px;
    width: 90%;
    margin: 0 auto;
  }
  
  .profile .profile-ground img {
    opacity: 0.1;
    width: 100%;
    height: 335px;
    border-radius: 20px 0 0 20px;
  }
  
  .profile .profile-user {
    background-color: #fff;
    /* min-height: 180vh; */
    width: 100%;
    margin: 0 auto;
    /* position: absolute; */
    /* left: 50%; */
    transform: translateX(0%);
    top: -100px;
    position: relative;
    border-radius: 10px;
  }
  
  .profile .info {
    text-align: right;
  }
  
  .profile .info  h2 {
    font-weight: 500;
    font-size: 1.8rem;
    /* text-shadow: -10px 0pxpx #666; */
    margin-bottom: 15px;
  }
  
  .profile .info .line {
    opacity: 0.6;
    width: 70%;
    height: 4px;
    background-color: #e02a24;
    left: 186px;
    display: block;
    margin-left: auto;
  }
  
  .profile .info .fa-phone {
    color: #e02a24;
  }
  
  .profile .info .fa-envelope {
    color: #FFBF00;
  }
  
  .profile .info .number {
    margin-top: 15px;
  }
  
  .profile .info span {
    font-weight: 500;
  }
  
  
  
  .profile .info-course {
      text-align: right;
      margin-top: 70px;
  }
  
  .profile .line {
    width: 50%;
    height: 4px;
    display: block;
    background-color: #3586ff;
    opacity: 0.7;
    margin-left:auto ;
  }
  
  .profile .about-course {
    /* margin-right: 65px; */
    margin-top: 50px;
  }
  
  .profile .about-course h2,
  .profile .analysis-platform h2 {
    font-weight: 600;
    text-align: center;
    margin-right: auto;
  }
  
  .profile h2 span{
    color: #e02a24;
    text-shadow: 0px 0px 15px #e02a24;
  }

  .Analysis {
    justify-content: center;
    flex-direction: row-reverse;
  }

  .Analysis .analysis-text{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }

  .Analysis .box-1, 
  .Analysis .box-2, 
  .Analysis .box-3 {
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding:  10px;
    border-radius: 50%;
    background-color: #f6f6f6;
    /* margin-right: 15px; */
    width: 170px;
    margin-bottom: 20px;
  }

  @media (max-width:767px) {
    .Analysis .box-1, 
  .Analysis .box-2, 
  .Analysis .box-3 {
    width: 100%;
  }
  }


  .Analysis .box-1   .CircularProgressbar .CircularProgressbar-path
{
    stroke: #e02a24;
    /* fill: black; */
  }
 
  .Analysis .box-2   .CircularProgressbar .CircularProgressbar-path
{
    stroke: #3586ff;
    /* fill: black; */
  }
 
  .Analysis .box-3   .CircularProgressbar .CircularProgressbar-path
{
    stroke: #FFBF00;
    /* fill: black; */
  }

  .Analysis .wallet {
    margin-right: 0;
    margin-top: 3px ;
    align-items: center;
    justify-content: center;
}

  .Analysis .wallet .num-quiz{
    background-color: #3586ff;
}

  .Analysis .wallet .num-video{
    background-color: #e02a24;
}

  .Analysis .wallet .num-total{
    background-color: #FFBF00;
}

@media (max-width:767px) {
  .Analysis .wallet {
    width: 50%;
    margin-bottom: 30px;
    align-items: center;
    margin: 30px auto;
    justify-content: center;

  }
}

.Analysis .wallet div:last-child,
.Analysis .wallet div:first-child{
    padding: 7px 15px;
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    width: fit-content;
    border-radius: 10px;
}

.Analysis .wallet div:first-child {
  background-color: #FFBF00;
  /* margin: 0 0 0 auto; */
}

.Analysis .wallet .name {

  margin: 0 10px;
    font-size: 22px;
    font-weight: 600;
}


  
  
  
  
  
 
  
  .profile h3 span {
    color: #e02a24;
  }
  
  .profile .mood{
      color: #e02a24;
  } 

  #light {
    background-color: #e6e6e6;
  }
  
  #dark { 
     background-color: #333333;
  } 

  .profile .analysis-platform .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 15px;
    margin-top: 25px;
    /* border-bottom: 3px solid #e6e6e6; */
  }

  .profile .analysis-platform .box span {
    margin-left: 100px;
    font-size: 1.3rem;
    color: #fff;

  }

  .profile .analysis-platform .box span {
    /* border: 3px solid #e02a24; */
    padding: 5px 10px;
    border-radius: 10px;
  }

  .profile .analysis-platform .box  span.one {
    background-color: #3586ff;
    font-weight: 600;
  }

  .profile .analysis-platform .box  span.two {
    background-color: #e02a24;
    font-weight: 600;
  }

  .profile .analysis-platform .box  span.three {
    background-color: #FFBF00;
    font-weight: 600;
  }

  .profile .analysis-platform .box  span.four {
    background-color: #e02a24;
    font-weight: 600;
  }

  .profile .analysis-platform .line:last-child {
    background-color: #e02a24;
  }

  .profile .analysis-platform .line:nth-child(5) {
    background-color: #e02a24;
  }

  .profile .analysis-platform .line:nth-child(7) {
    background-color: #FFBF00;
  }

  .profile .analysis-platform .box h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;

  }
  
  
  @media (max-width:767px) {

    .content-course .main-title {
      padding: 15px 15px;
    }
    
    .profile .about-course h2 {
      width: 100%;
    }
    .profile .analysis {
      margin-left: -33px;
      display: block;
    }
    .profile .analysis-bar {
      width: 100%;
      margin-bottom: 30px;
    }
    
  .profile .analysis-platform .box span  {
    margin-left: 0;
  }
  }
  
  @media (min-width:767px) and (max-width:1023px) {
    .profile .analysis {
      margin-left: 0;
    }
    .profile .main-info {
      margin-top: 55%;
    }
  }

  .button-prof {
    all: unset;
    font-size: 20px;
    font-weight: 600;
    background: transparent;
    border: none;
    position: relative;
    color: #f0f0f0;
    cursor: pointer;
    z-index: 1;
    padding: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-align: center;
    margin:  0 auto;
  }
  
  .button-prof::after,
  .button-prof::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -99999;
    transition: all .4s;
  }
  
  .button-prof::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    background: #e03a56;
    border-radius: 10px;
  }
  
  .button-prof::after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
  }
  
  .button-prof:hover::before {
    transform: translate(5%, 20%);
    width: 110%;
    height: 110%;
  }
  
  .button-prof:hover::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }
  
  .button-prof:active::after {
    transition: 0s;
    transform: translate(0, 5%);
  }          

  .title-profile {
    width: 100%;
  }
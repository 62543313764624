.pay {
    min-height: 100vh;
    background-color: #e6e6e6;
  }

  .pay-smooth {
    /* width: 0px; */
    height: 100%;
    background-color: #ffffff;
    position: fixed;
    right: 0  ;
    z-index: 9999999;
    scroll-behavior: smooth;
    /* transition: 0.3s; */
    /* top: 0; */
    overflow-y : scroll;
    overflow-x: hidden;
    transition: width 0.3s ease-out;

  }

  @media screen and (min-width: 768px) {
    .pay-smooth {
      width: 450px;
    }
  }
  
  @media screen and (max-width: 767px) {
    .pay-smooth {
      width: 345px;
    }
  }

  /* .pay-smooth.active {
    width: 300px;
  } */

  .pay-smooth::-webkit-scrollbar {
    width: 0.5rem;
    height: 100%;

  }

  .pay-smooth::-webkit-scrollbar-thumb {
    background-color: #f43f5e;
    border-radius: 20px;
  }
  .pay-smooth::-webkit-scrollbar-track {
    background-color: #000;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0.5;
    z-index: 999999;
    }

  
  .toggle-btn {
        border: none;
        width: 100%;
       padding:28px 0 ;
       border-radius: 10px;
       margin-top: 20px;
       font-size: 18px;
       font-weight: 600;
       touch-action: manipulation;
       background-color: #e6e6e6;
       transition: 0.3s;
       border: 3px solid rgb(244, 63, 94);
       line-height: 15px;

  }

  .toggle-btn:hover {
    background-color: rgb(244, 63, 94);
    color: #fff;
  }

  .pay-smooth .content-card {
    background-color: #e6e6e6;
    padding: 10px;
    /* width: 100%; */
    margin-top: 15px;
  }

  .close-btn {
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    background-color: #e6e6e6;
    /* margin-right: 10px; */
    }

    .close-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 15px;
    }
    

    @media (max-width:767px) {
      .close-title {
        padding-right: 10px;
      }
    }

    .close-title h2 {
      font-weight: 600;
      font-size: 24px;
      margin-top: 8px;
    }
    .close-title span {
      color: #f43f5e;
    }

    .pay-smooth .quiz-btn,
    .pay-smooth .video-btn {
      width: 100%;
      padding: 15px;
      border-radius: 20px;
      color: #fff;
      transition: 0.3s;
      display: block;
      text-decoration: none;
      text-align: center;
    }

    .pay-smooth .quiz-btn {
      background-color: rgb(244, 63, 94);
      border: 2px solid rgb(244, 63, 94);
    }
    .pay-smooth .video-btn {
      background-color: #ffa200;
      border: 2px solid #ffa200;
    }


    .pay-smooth .quiz-btn:hover,
     .pay-smooth .video-btn:hover{
      background-color: #e6e6e6;
      color: #000;
    }

    .pay-smooth .content-card {
      border-radius: 10px;
      padding: 20px 10px;
      margin-bottom: 20px;
    }

    .content-lesson {
      text-align: right;
      margin-top: 15px;

    }
    .pay-smooth .content-card p {
      font-weight: 600;
    }

    .pay-smooth .content-card .quiz-span {
      color: rgb(244, 63, 94);
      margin-left: 3px;
    }
    .pay-smooth .content-card .video-span {
      color: #ffa200;
      margin-left: 3px;
    }

    .pay-smooth .content-main {
      padding: 10px;
      height: 100%;
      margin-bottom: 80px;
    }

    .pay-smooth .content-card .title {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 3px solid #ffffff;
      padding-bottom: 15px;
    }

    .pay-smooth .content-card .title h2 {
      font-size: 25px;
      font-weight: 600;
      margin-right: 10px;
      margin-bottom: 0;
    }

    .pay-smooth .content-card .title .fa-file-circle-question {
      color: #f43f5e;
      font-size: 23px;
    }
    .pay-smooth .content-card .title .fa-video {
      color: #ffa200;
      font-size: 23px;
    }
    .pay-smooth .content-card .title .fa-file-pdf {
      color: #ffa200;
      font-size: 23px;
    }


  @media (max-width:767px) {
    .pay {
      padding-bottom: 55px;
    }
  }


  @media (max-width:767px) {

    .pay .main-title {
      padding: 15px 15px;
    }
  }
  
   .pay .info {
    background-image: linear-gradient( 135deg, #f43f5e 50% , #eeeeee 100%);
    min-height: 50vh;
    background-size: cover;
    z-index: -999;
    background-position: center top;
    /* position: relative; */
    width: 100%;
    margin-right: 27%;
    background-color: #e6e6e6;
    border-radius: 15px;
 
  } 

  .pay .main {
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.7),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.7);
    padding: 15px;
    border-radius:15px ;
  }
  
  @media (max-width:767px) {
    .pay .info {
        width: 75%;
    }
  }
  .pay .info .image {
    overflow: hidden;
  }
  .pay .info img {
    height: 50%;
    opacity: 0.1;
    position: absolute;
    width: 64%;
  }
  
  .pay .info .content {
    text-align: right;
    /* margin-top: 40px; */
    /* margin-right: 40px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
  }
  
  
  .pay .info .content h2 {
    font-weight: 600;
    margin-bottom: 80px;
  }
  
  .pay .info .content  p{
    font-weight: 600;
    text-align: center;
  }

  .pay .info .content .date {
    background-color: #FFBF00;
    font-weight: 500;
    font-size: 1.1rem;
    padding: 5px 25px;
    border-radius: 25px;
    margin-right: 32px;
    text-align: right;
  }
  
  @media (max-width:767px) {
    .pay .info .content .date  {
      display: block;
      margin-top: 15px;
    }
    .pay .info .content h2  {
      margin-bottom: 50px;
      text-align: center;
    }
    .pay .info .content {
      width: 100%;
    }
  }
  
  .pay .info-course {
    align-items: center;
      justify-content: space-around;
      flex-direction: row-reverse;
      margin-top: 75px;
  }
  
  @media (max-width:767px) {
    .pay .info-course {
      flex-direction: column;
    }
    .pay .info {
        width: 100%;
      }
    .pay .info img {
      width: 85%;
      height: 68%;
  }
    
  }
  
  .pay .info-course h3 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #000000;
    background-color: #FFBF00;
    padding: 5px 30px;
    border-radius: 25px;
    margin-bottom: 38px;
  }
  
  .pay .info-course .about span {
    margin-right: 10px;
    color: #ffffff;
  }
  
  .pay .accord {
    border-radius: 0 0 20px 20px;
  } 
  
  @media (min-width:767px) and (max-width:1023px) {
    .pay .info {
      width: 100%;
    }
    .pay .info img {
      width: 73%;
      height: 50%;
    }
    .pay .info .content .date  {
        margin-right: 5px;
    }
  }

  .pay .accordion-flush .accordion-item {
    border-radius:20px 20px 20px 20px ;
   
  } 

    .pay .accord {
      box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
      inset -5px -5px 5px rgba(255 , 255 , 255 , 0.7),
      -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
      -5px -5px 5px rgba(255 , 255 , 255 , 0.7);
      padding: 20px;
      border-radius:20px ;
      margin: 100px 0;

    }
  .pay .accordion-flush  .accordion-item button.accordion-button {
    box-shadow: none;
    background-color: transparent;
    color: #000000;
    font-weight: 600;
    border-radius:20px 20px 20px 20px ;
    padding: 30px 20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-size: 24px;
    background-color: #f6f6f6;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  .pay .body-accord {
    background: #fff;
    padding: 10px 20px;
    width: 100%;
    margin: 0 0 0 auto;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  

  .pay .body-title  .fa-file-circle-question{
      color: #f43f5e;
      margin-left: 15px;
  }

  .pay .body-title  .fa-video,
  .pay .fa-file-pdf{ 
      color: #FFBF00;
      margin-left: 15px;
  }

  .pay .body-title h2 {
    font-size: 24px;
    margin-bottom: 0;
  }

  @media (max-width:767px) {
    .pay .body-accord {
      width: 100%;
      flex-direction: column-reverse;
      padding: 10px 20px 10px 5px;
    }
  }

  @media (min-width:768px) and (max-width: 1024px) {
    .pay .body-accord {
      width: 100%;
      /* flex-direction: column-reverse; */
    }
  }


  

  .button-57 {
    position: relative;
    overflow: hidden;
    border: 1px solid #18181a;
    color: #18181a;
    display: inline-block;
    line-height: 15px;
    padding: 28px;
    text-decoration: none;
    cursor: pointer;
    background: #fff;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;

  }
  
  .button-57 span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
  }
  
  .button-57 span:last-child {
    color: white;
    display: block;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 14px;
    line-height: 13px;
  }
  
  .button-57:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f43f5e;
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
  }
  
  .button-57:hover:after {
    transform-origin: bottom center;
    transform: skewY(9deg) scaleY(2);
  }
  
  .button-57:hover span:last-child {
    transform: translateX(-50%) translateY(-50%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
  }

  .pay .body-accord .content-1 ,
  .pay .body-accord .content-2 {
  text-align: right;
  }
  .pay .body-accord .content-1 p ,
  .pay .body-accord .content-2 p{
        margin-bottom: 0;
        font-size: 17px;
  }
  .pay .body-accord .content-1 span{
    color: #f43f5e;
    font-weight: 600;
    margin-right: 8px;
  }
  .pay .body-accord .content-2 span{
    color: #FFBF00;
    font-weight: 600;
    margin-right: 8px;

  }


  .pay .quiz-btn,
  .pay .video-btn  {
    text-decoration: none;
      /* display: block; */
      padding: 10px 30px;
      border-radius: 20px;
      color: #ffffff;
      transition: 0.3s;
  }

  @media (max-width:767px) {
    .pay .quiz-btn,
    .pay .video-btn {
      margin: 10px 0;
    }
  }

  .pay .quiz-btn {
    border: 2px solid #f43f5e;
      background-color: #f43f5e;
  }

  .pay .video-btn {
    border: 2px solid #FFBF00;
      background-color: #FFBF00;
  }

  .pay .quiz-btn:hover {
          background-color: #fff;
        color: #000000;
  }

  .pay .video-btn:hover {
          background-color: #fff;
        color: #000000;
  }

  
  .pay .accordion-flush .accordion-item button.accordion-button h2 {
    font-weight: 600;
  }

  .pay .accordion-flush .accordion-item button.accordion-button h2  span {
    color: #f43f5e;
    border-bottom: 5px solid #e6e6e6;
    padding-bottom: 10px;
   
  }

  .pay .accordion-flush .accordion-item button::after {
    transition: 0.3s;
    font-weight: 600;
    font-size: 20px;
    margin: 10px;
  }

  .pay button.accordion-button.collapsed {
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
    text-align: right;
  }
  .pay  .box{
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
    background-color: #ffff;
    border-radius:20px ;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pay  .box .fa-quiz {
    color: #f43f5e;
    font-size: 30px;
  }
  
  .pay .box .fa-file-circle-question{
        color: #FFBF00;
        font-size: 30px;
  }
  .pay .box .fa-file-pdf{
        color: #555555;
        font-size: 30px;
  }
  .pay .accordion-body {
    background-color: #e6e6e6;
  }

 
 
  
  










.pay .box-toggle {

  padding: 0;
}

.pay .box-toggle .content-toggle {
 
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-direction: row-reverse;

}

.pay .box-toggle .content-toggle .title{
  margin-left: 570px;
}


.pay .image-main-title {
  box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
  inset -5px -5px 5px rgba(255 , 255 , 255 , 0.7),
  -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
  -5px -5px 5px rgba(255 , 255 , 255 , 0.7);
  padding: 15px;
  /* border-radius:20px ; */
  border-radius: 15px;
  width: 100%;
  height: 100%;
}

.pay .card-pay {
  box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
  inset -5px -5px 5px rgba(255 , 255 , 255 , 0.7),
  -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
  -5px -5px 5px rgba(255 , 255 , 255 , 0.7);
  padding: 15px;
  border-radius:15px ;
}
.pay .image-title {
  width: 100%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 15px;
  height: 100%;
}

@media (max-width:767px) {
  .pay .image-main-title {
    height: 100%;
    margin-top: 20px;
    margin-bottom: 40px;
    display: none;
  }
}
@media (min-width:767px) and (max-width:1023px){
  .pay .image-main-title {
    height: 100%;
    margin-top: 40px;
    display: none;
  }
}

.pay .card-pay .price {
  background-color: #FFBF00;
  width: fit-content;
  padding: 5px 30px;
  /* margin-top: 10px; */
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  margin: 20px  auto 0;
  display: flex;
  flex-direction: row-reverse;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  
}

.pay .info-pay {
  margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 10px 20px;
    border-radius: 15px;
}

.pay .info-pay > div {
  justify-content: space-between;
  flex-direction: row-reverse;
  font-size: 18px;
  font-weight: 600;
  color: #555555;
}

.pay .info-pay .watch-count {
  flex-direction: row-reverse;
}

.prize-p {
  flex-direction: row-reverse;
} 

.custom-progress-bar-quiz .progress-bar {
  background-color: #f43f5e;
  box-shadow: inset 0 0 10px rgb(0 0 0 / 15%);
  font-size: 15px;

}

.custom-progress-bar-quiz  {
  background-color: green;
}





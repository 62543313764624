
 .sidebar-profile   .navigation {
    position: relative;
    top: 0;
    right: 20px;
    bottom: 50%;
    width: 300px;
    border-radius: 10px;
    box-sizing: initial;
    border-left: 5px solid #e03a56;
    background: #e03a56;
    transition: width 0.5s;
    overflow-x: hidden;
    margin-left: auto;
    text-align: center;
  }
  .sidebar-profile .navigation::-webkit-scrollbar {
    display: none;
    }
    
    .sidebar-profile   .navigation.active{
      width: 300px;
    }
    .sidebar-profile  .navigation ul{
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      padding-left: 5px;
      padding-top: 75px;
      background-color: #e03a56;
    }
    .sidebar-profile  .navigation ul li {
      position: relative;
      list-style: none;
      width: 100%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      transition: 0.3s;
    }
    
    .sidebar-profile .navigation ul li.active {
      background: #fff;
    }
    
    .sidebar-profile   .navigation ul li:hover {
      background-color: #fff;
    }
    
    .sidebar-profile  .navigation ul li b:nth-child(1) {
      position: absolute;
      bottom: -13px;
      height: 13px;
      width: 100%;
      background: #fff;
      display: none;
    }
    .sidebar-profile  .navigation ul li b:nth-child(1)::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-bottom-right-radius: 20px;
      background: #e03a56;
    }
    .sidebar-profile   .navigation ul li b:nth-child(2) {
      position: absolute;
      bottom: -13px;
      height: 13px;
      width: 100%;
      background: #fff;
      display: none;
    }
    .sidebar-profile .navigation ul li b:nth-child(2)::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top-right-radius: 20px;
      background: #e03a56;
    }
    .sidebar-profile   .navigation ul li.active b:nth-child(1),
    .sidebar-profile   .navigation ul li.active b:nth-child(2) {
      display: block;
    }
    
    .sidebar-profile  .navigation ul li:hover b:nth-child(1),
    .sidebar-profile  .navigation ul li:hover b:nth-child(2) {
      display: block;
    }
    
    .sidebar-profile  .navigation ul li a {
      position: relative;
      display: block;
      width: 100%;
      display: flex;
      text-decoration: none;
      color: #fff;
    }
    .sidebar-profile .navigation ul li.active a,
    .sidebar-profile   .navigation ul li:hover a{
      color: #333;
    }
    .sidebar-profile  .navigation ul li a .icon{
      position: relative;
      display: block;
      min-width: 60px;
      height: 60px;
      line-height: 70px;
      text-align: center;
      color: #fff;
    }
    .sidebar-profile  .navigation ul li.active .icon,
    .sidebar-profile .navigation ul li:hover .icon{
      color: #FFBF00;
    }
    .sidebar-profile  .navigation ul li a .icon i{
      font-size: 1.5em;
    }
    .sidebar-profile  .navigation ul li a .title{
      position: relative;
      display: block;
      padding-left: 10px;
      height: 60px;
      line-height: 60px;
      white-space: normal;
      font-weight: 500;
    }
    .sidebar-profile .toggle{
      position: absolute;
      top: 263px;
      right: 43px;
      width: 50px;
      height: 50px;
      background: #e03a56;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999999;
    
    }
    .sidebar-profile  .toggle.active{
      background: #ff4d89;
    }
    .sidebar-profile .toggle ion-icon{
      position: absolute;
      color: #fff;
      font-size: 34px;
      display:none;
    }
    .sidebar-profile .toggle ion-icon.open,
    .sidebar-profile .toggle.active ion-icon.close{
      display:block;
    }
    .sidebar-profile .toggle ion-icon.close,
    .sidebar-profile  .toggle.active ion-icon.open{
      display:none;
    }


@media (max-width:767px) {
    .sidebar-profile   .navigation {
        /* top: 194px;
        bottom: 74%; */
        position: inherit;
      }
      .sidebar-profile   .navigation ul {
        top: 200px;
        padding: 30px 0;
        position: absolute;
       
      }
      .sidebar-profile .toggle  {
        /* transform: translateY(90px); */
        display: none;
      }
}

@media (min-width:767px) and (max-width:1023px) {
 
  .sidebar-profile   .navigation {
    /* top: 194px;
    bottom: 74%; */
    position: inherit;
  }
  .sidebar-profile .navigation ul {
    top: 200px;
    padding: 30px 0;
    position: absolute;
   
  }
  .sidebar-profile .toggle  {
    /* transform: translateY(90px); */
    display: none;
  }
}
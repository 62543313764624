
.charge-wallet {
    /* min-height: 100vh; */
    background-color: #e6e6e6;
  
   padding-top: 70px;
   width: 100%;
}

.charge-wallet .quiz-content .codes{
    /* min-height: 100vh; */
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
} 

.charge-wallet .quiz-content .codes form {
    border: 2px solid #e6e6e6;
    padding: 45px 25px;
    border-radius: 20px;
    display: flex;
}

.charge-wallet .quiz-content input {
    width: 100%; 
    padding: 12px 15px;
    background-color: #f3f3f3;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 20px;
}
.charge-wallet .codes .create {
    font-size: 1.7rem;
    font-weight: 600;
    margin-left: 10px;
}

.charge-wallet .quiz-content label {
    font-size: 1.1rem;
    font-weight: 600;
} 

.charge-wallet .quiz-content .search-button,
.charge-wallet .quiz-content .create-button {
    height: 100%;
    margin: 35px 0 0 0;
    padding: 9px 50px;
    font-size: 1.2rem;
}


.charge-wallet .quiz-content input:focus {
    border: none;
    outline: none;
}

.charge-wallet .table-code {
    border-top: 2px solid #e6e6e6;
    margin-top: 30px
    ;
}

.charge-wallet button {
    height: 100%;
    padding: 10px 40px;
}


@media (max-width:767px) {
    .charge-wallet{
        padding: 70px 0;
        width: 100%;
    }
    .sidebar {
            width: 0;
    }
    .charge-wallet .quiz-content .codes form {
        flex-direction: column;
}
.charge-wallet .wallet span:first-child {
    padding: 17px 12px 18px 0px;
}

.charge-wallet button {
  width: 100%;
}
}

.charge-wallet .wallet {
    margin-top: 30px;
    margin-left: 7px;
    border: 2px solid #eab308;
    width: -moz-fit-content;
    width: fit-content;
    /* padding: 14px 0 14px 30px; */
    border-radius: 25px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.charge-wallet .wallet span:first-child {
    background-color: #eab308;
    padding: 17px 29px 18px 30px;
    border-radius: 20px;
    margin-left: 15px;
}

.charge-wallet .wallet span:last-child {
    padding-left: 20px;
}


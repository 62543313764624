
.add-question  {
    /* min-height: 100vh; */
    background-color: #e6e6e6;
   /* width: calc(100% - 300px); */
   width: 100%;
   padding-top: 70px;
}

.add-question .question {
    background-color: #fff;
    padding: 30px;
    /* min-height: 100vh; */
    border-radius: 20px;
}

.add-question .quiz-content .question form {
    border: 2px solid #e6e6e6;
    padding: 45px 25px;
    border-radius: 20px;
    /* display: flex; */
}

.add-question .quiz-content input, 
.add-question .quiz-content textarea,
.add-question .quiz-content Select
 {
    width: 100%;
    padding: 12px 15px;
    background-color: #f3f3f3;
    border: none;
    font-size: 1rem;
    font-weight: 400;
}


.add-question .title {
    font-size: 1.7rem;
    font-weight: 600;
    margin-left: 10px;
}

.add-question .quiz-content label {
    font-size: 1.1rem;
    font-weight: 600;
} 

.add-question .quiz-content .search-button,
.add-question .quiz-content .create-button {
    height: 100%;
    margin: 35px 0 0 0;
    padding: 9px 50px;
    font-size: 1.2rem;
}

.add-question .chooices {
    justify-content: space-between;
}


.add-question .quiz-content input:focus {
    border: none;
    outline: none;
}

.add-question .table-code {
    border-top: 2px solid #e6e6e6;
    margin-top: 30px
    ;
}

.add-question .quiz-content .image {
    width: 20%;
}


@media (max-width:767px) {
    .add-question{
        padding: 70px 0;
        width: 100%;
    }
    .sidebar {
            width: 0;
    }
    .add-question .quiz-content .codes form {
        flex-direction: column;
}
.add-question .quiz-content .image {
    width: 100%;
}
}

@media (min-width:767px) and (max-width:1023px) {
    .add-question .quiz-content .image {
        width: 100%;
}
}

.img-edit {
    width: 100%;
    height: 100%;
}


.add-quiz  {
    /* min-height: 100vh; */
    background-color: #e6e6e6;
   width: 100%;
   padding-top: 70px;
}

.add-quiz .codes {
    background-color: #fff;
    padding: 30px;
    /* min-height: 100vh; */
    border-radius: 20px;
}

.add-quiz .quiz-content .question form {
    border: 2px solid #e6e6e6;
    padding: 45px 25px;
    border-radius: 20px;
    /* display: flex; */
}

.add-quiz .quiz-content input, 
.add-quiz .quiz-content textarea,
.add-quiz .quiz-content Select
 {
    width: 100%;
    padding: 12px 15px;
    background-color: #f3f3f3;
    border: none;
    font-size: 1rem;
    font-weight: 400;
}

.add-quiz .create {
    font-size: 1.7rem;
    font-weight: 600;
    margin-left: 10px;
}

.add-quiz .quiz-content label {
    font-size: 1.1rem;
    font-weight: 600;
} 

.add-quiz .quiz-content .search-button,
.add-quiz .quiz-content .create-button {
    height: 100%;
    margin: 35px 0 0 0;
    padding: 9px 50px;
    font-size: 1.2rem;
}

.add-quiz .chooices {
    /* justify-content: space-between; */
    /* border-bottom: 1px solid #e6e6e6; */
    padding-bottom: 25px;
}
.add-quiz .group {
    width: 50%;
}

.add-quiz .quiz-content input:focus {
    border: none;
    outline: none;
}

.add-quiz .table-code {
    border-top: 2px solid #e6e6e6;
    margin-top: 30px;
}

.add-quiz .quiz-content .image {
    width: 20%;
}
input#formBasicCheckbox {
    background-color: #e03a56;
    padding: 10px;
}

@media (max-width:767px) {
    .add-quiz{
        padding: 70px 0;
        width: 100%;
    }
    .sidebar {
            width: 0;
    }
    .add-quiz .quiz-content .codes form {
        flex-direction: column;
}
    .add-quiz .quiz-content .image {
    width: 100%;
}
.add-quiz .group {
    width: 100%;
}
}
@media (min-width:767px) and (max-width:1023px) {
    .add-quiz{
        padding: 70px 0;
        /* width: 100%; */
    }
    /* .sidebar {
            width: 0;
    } */
    .add-quiz .quiz-content .codes form {
        flex-direction: column;
}
    .add-quiz .quiz-content .image {
    width: 100%;
}
.add-quiz .group {
    width: 100%;
}
}




.image-container {
    position: relative;
  }
  
  .image-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
  }
  
  .image-container img.clicked {
    position: fixed;
    top: 15%;
    left: 10%;
    width: 80%;
    height: 80%;
    z-index: 9999;
  }

  @media (max-width:767px) {
    .image-container img.clicked {
        position: fixed;
        top: 20%;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: 9999999;
    }
  
  }
  


  
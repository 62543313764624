


.homepage-admin {
    min-height: 100vh;
    background-color: #e6e6e6;
   width: 100%;
   padding-top: 70px;
}

.sidebar {
    width: auto;
    margin-left: auto;
    background-color: #e6e6e6;
}

.homepage-admin .container {
    /* max-width: 1545px; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px 0; */
}

.homepage-admin .main-title {
    margin-bottom: 40px;
}

.homepage-admin .card {
    position: relative;
  
    height: 440px;
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 50px 50px;
    border-radius: 15px;
    margin: 30px 10px;
    transition: 0.5s;
}
 .homepage-admin .card .box {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    height: 390px;
    /* background: rgba(255, 191, 0, 0.8); */
    background-color: #e6e6e6;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    transition: 0.5s;
}
    .homepage-admin .card .box .content {
        padding: 20px 7px;
        text-align: center;
        transform: translateY(50%);
    }  

    .homepage-admin .card:hover .box {
        transform: translateY(-50px);
    }

    .homepage-admin .card .box .content h2{
        position: absolute;
        top: -20%;
        /* right: 65px; */
        /* font-size: 8em; */
        color: rgba(0 , 0 , 0 , 0.04);
        transition: 0.5s;
        font-size: 4rem;
        text-transform: capitalize;
        text-align: center;
        font-weight: 600;
        transform: translateY(50%);
        right: 25%;
        /* right: 0; */

    }  

    .homepage-admin .card:hover .box .content h2{
        color: rgba(0 , 0 , 0 , 0.09);
    }  

    .homepage-admin .card .box .content h3{
        font-weight: 600 ;
        font-size: 1.9rem;
        transition: 0.5s;
    }  

    .homepage-admin .card:hover .box .content h3{
        /* color: rgba(0 , 0 , 0 , 0.05); */
        z-index: 2;
        /* font-size: 2rem; */
    }  

    .homepage-admin .card .box .content a {
        
    text-decoration: none;
    padding: 10px 35px;
    border: 1px solid #e03a56;
    color: black;
    margin-top: 30px;
    display: block;
    /* width: -moz-fit-content; */
    width: fit-content;
    margin: 0 auto;
    border-radius: 25px;
    transition: 0.3s;
    }

    .homepage-admin .card .box .content a:hover,
    .homepage-admin .card:hover .box  a{
        background-color: #e03a56;
        color: white;
    }

    @media (max-width:767px) {
        .homepage-admin {
            padding: 70px 0;
            width: 100%;
        }
        .sidebar {
                width: 0;
        }
        .homepage-admin .card .box .content h2  {
            top: -10%;
            transform: translateY(20%);
            width: 100%;
            right: 0%;
        }
    }

    @media (min-width:767px) and (max-width:1023px)  {
        .homepage-admin .card .box .content h2 {
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            top: 0;
        }
        /* .homepage-admin .row {
            flex-direction: column-reverse;
        } */
    }

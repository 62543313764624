

.titlefile {
    font-weight: 600;
    margin-top: 80px;

}

.date-file {
    font-size: 20px;
}
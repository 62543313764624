.add-homework   {
    /* min-height: 100vh; */
    background-color: #e6e6e6;
   width: 100%;
   padding-top: 70px;
}

.add-homework  .codes {
    background-color: #fff;
    padding: 30px;
    /* min-height: 100vh; */
    border-radius: 20px;
}

.add-homework  .quiz-content .question form {
    border: 2px solid #e6e6e6;
    padding: 45px 25px;
    border-radius: 20px;
    /* display: flex; */
}

.add-homework  .quiz-content input, 
.add-homework  .quiz-content textarea,
.add-homework  .quiz-content Select
 {
    width: 100%; 
    padding: 12px 15px;
    background-color: #f3f3f3;
    border: none;
    font-size: 1rem;
    font-weight: 400;
}

.add-homework  .create {
    font-size: 1.7rem;
    font-weight: 600;
    margin-left: 10px;
}

.add-homework  .quiz-content label {
    font-size: 1.1rem;
    font-weight: 600;
} 

.add-homework  .quiz-content .search-button,
.add-homework  .quiz-content .create-button {
    height: 100%;
    margin: 35px 0 0 0;
    padding: 9px 50px;
    font-size: 1.2rem;
}

.add-homework  .chooices {
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 25px;
}

.add-homework  .chooices input {
    width: 500px;
}
.add-homework  .chooices select {
    width: 500px;
}

.add-homework  .quiz-content input:focus {
    border: none;
    outline: none;
}

.add-homework  .table-code {
    border-top: 2px solid #e6e6e6;
    /* margin-top: 30px */
    ;
}

.add-homework  .quiz-content .image {
    width: 20%;
}

@media (max-width:767px) {
    .add-homework {
        padding: 70px 0;
        width: 100%;
    }
    .sidebar {
            width: 0;
    }
    .add-homework  .quiz-content .codes form {
        flex-direction: column;
}
.add-homework  .quiz-content .image {
    width: 100%;
}
.add-homework .chooices input {
    width: 100%;
}
.add-homework  .chooices select {
    width: 100%;
}
}

@media (min-width:767px) and (max-width:1023px) {
    .add-homework .chooices input {
        width: 100%;
    }
    .add-homework  .chooices select {
        width: 100%;
    }
}



.add-admin {
    /* min-height: 100vh; */
    background-color: #e6e6e6;
   width: 100%;
   padding-top: 70px;
}

.add-admin .quiz-content .codes{
    /* min-height: 100vh; */
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
} 

.add-admin .quiz-content .codes form {
    border: 2px solid #e6e6e6;
    padding: 45px 25px;
    border-radius: 20px;
    /* display: flex; */
    /* justify-content: space-between; */
}

.add-admin .container {
    margin: 0 auto;
}

.add-admin  .input-group {
    display: flex;
    justify-content: space-between;
}

.add-admin .quiz-content input {
    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
    background-color: #f9f9f9;
}

.add-admin .quiz-content select {
    /* width: 96%; */
    background-color: #f9f9f9;
    margin-bottom: 5px;
    padding: 10px;
}
.add-admin .codes .create {
    font-size: 1.7rem;
    font-weight: 600;
    margin-left: 10px;
}

.add-admin .input-group .big-input {
    width: 45%;
}

.add-admin .quiz-content label {
    font-size: 1.1rem;
    font-weight: 600;
} 

.add-admin .quiz-content .search-button,
.add-admin .quiz-content .create-button {
    height: 100%;
    margin: 35px 0 0 0;
    padding: 9px 50px;
    font-size: 1.2rem;
}


.add-admin .quiz-content input:focus {
    border: none;
    outline: none;
}

.add-admin .table-code {
    border-top: 2px solid #e6e6e6;
    margin-top: 30px
    ;
}

.add-admin button {
    height: 100%;
    padding: 10px 40px;
}

.add-admin .error {
    color: red;
    font-weight: 500;
    font-size: 16px;
}


@media (max-width:767px) {
    .add-admin{
        padding: 70px 0;
        width: 100%;
    }
    .sidebar {
            width: 0;
    }
    .add-admin .quiz-content .codes form {
        flex-direction: column;
}

.add-admin button {
  width: 100%;
}

.add-admin  .input-group {
    display: flex;
    flex-direction: column;
}
.add-admin  .input-group .big-input {
    width: 100%;
}
}

@media (min-width:767px) and (max-width:1023px) {
    .add-admin  .input-group {
        display: flex;
        flex-direction: column;
    }
    .add-admin  .input-group .big-input {
        width: 100%;
    }
}


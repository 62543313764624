

.question-data{
    /* min-height: 100vh; */
    background-color: #e6e6e6;
   width: 100%;
   padding-top: 70px;
}

.question-data .sc-dkrFOg.iSAVrt.rdt_TableHeadRow  {
    padding: 10px 10px;
    border-top: 3px solid #e03a56;
    border-bottom: 3px solid #e03a56;
    font-size: 1rem;
    font-weight: 600;
    margin-top: -8px;
}

.question-data .search-table {
    width: 25%;
}

.edit-image {
    width: 50%;
    height: 50%;
}

.question-data #table-to-xls {
    display:none;
}
/* 
.chapter-data.table {
    display:none;
} */

 .sc-hHTYSt.dOIzqm.rdt_TableBody {
    padding: 10px;
    font-size: 2rem;
}

.question-data .quiz-content .codes{
    /* min-height: 100vh; */
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
} 

.question-data .quiz-content .codes form {
    border: 2px solid #e6e6e6;
    padding: 45px 25px;
    border-radius: 20px;
    display: flex;
}

.question-data .quiz-content input {
    /* width: 15%; */
    padding: 12px 15px;
    background-color: #f3f3f3;
    border: none;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 20px;
}
.question-data .codes .create {
    font-size: 1.7rem;
    font-weight: 600;
    margin-left: 10px;
}

.question-data header.sc-dIfARi.kDEcCH {
    border-radius: 0;
}

.question-data .quiz-content label {
    font-size: 1.1rem;
    font-weight: 600;
} 

.question-data .quiz-content .search-button,
.question-data .quiz-content .create-button {
    height: 100%;
    margin: 35px 0 0 0;
    padding: 9px 50px;
    font-size: 1.2rem;
}


.question-data .quiz-content input:focus {
    border: none;
    outline: none;
}

.question-data .table-code.cxcOiE {
        padding: 5px;
}

.question-data .table-code {
    border-top: 2px solid #e6e6e6;
    margin-top: 30px
    ;
}


@media (max-width:767px) {
    .question-data{
        padding: 70px 0;
        width: 100%;
    }
    .sidebar {
            width: 0;
    }
    .question-data    .search-table {
        width: 100%;
  
}
}

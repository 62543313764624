.sidebar-admin {
  height: 100%;
}
 .sidebar-admin   .navigation {
  position: relative;
  top: 0;
  right: 0;
  bottom: 50%;
  width: 70px;
  border-radius: 0;
  box-sizing: initial;
  border-left: 5px solid #e03a56;
  background: #e03a56;
  transition: width 0.5s;
  overflow-x: hidden;
  margin-left: auto;
  min-height: 100%;
}
.sidebar-admin .navigation::-webkit-scrollbar {
  display: block;
  width: 7px;
  }

.sidebar-admin .navigation::-webkit-scrollbar-thumb {
  background-color: wheat;
 
  }
  
  .sidebar-admin   .navigation.active{
    width: 300px;
  }
  .sidebar-admin  .navigation ul{
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 5px;
    padding-top: 85px;
    background-color: #e03a56;
  }
  .sidebar-admin  .navigation ul li {
    position: relative;
    list-style: none;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: 0.3s;
  }
  
  .sidebar-admin .navigation ul li.active {
    background: #fff;
  }
  
  .sidebar-admin   .navigation ul li:hover {
    background-color: #fff;
  }
  
  .sidebar-admin  .navigation ul li b:nth-child(1) {
    position: absolute;
    bottom: -13px;
    height: 13px;
    width: 100%;
    background: #fff;
    display: none;
  }
  .sidebar-admin  .navigation ul li b:nth-child(1)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-bottom-right-radius: 20px;
    background: #e03a56;
  }
  .sidebar-admin   .navigation ul li b:nth-child(2) {
    position: absolute;
    bottom: -13px;
    height: 13px;
    width: 100%;
    background: #fff;
    display: none;
  }
  .sidebar-admin .navigation ul li b:nth-child(2)::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    background: #e03a56;
  }
  .sidebar-admin   .navigation ul li.active b:nth-child(1),
  .sidebar-admin   .navigation ul li.active b:nth-child(2) {
    display: block;
  }
  
  .sidebar-admin  .navigation ul li:hover b:nth-child(1),
  .sidebar-admin  .navigation ul li:hover b:nth-child(2) {
    display: block;
  }
  
  .sidebar-admin  .navigation ul li a {
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: #fff;
  }
  .sidebar-admin .navigation ul li.active a,
  .sidebar-admin   .navigation ul li:hover a{
    color: #333;
  }
  .sidebar-admin  .navigation ul li a .icon{
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 70px;
    text-align: center;
    color: #fff;
  }
  .sidebar-admin  .navigation ul li.active .icon,
  .sidebar-admin .navigation ul li:hover .icon{
    color: #FFBF00;
  }
  .sidebar-admin  .navigation ul li a .icon i{
    font-size: 1.5em;
  }
  .sidebar-admin  .navigation ul li a .title{
    position: relative;
    display: block;
    padding-left: 10px;
    height: 60px;
    line-height: 60px;
    white-space: normal;
    font-weight: 500;
  }
  .sidebar-admin .toggle{
    position: absolute;
    top: 95px;
    right: 12px;
    width: 50px;
    height: 50px;
    background: #e03a56;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    /* visibility: hidden; */
  
  }
  .sidebar-admin  .toggle.active{
    background: #ff4d89;
  }
  .sidebar-admin .toggle ion-icon{
    position: absolute;
    color: #fff;
    font-size: 34px;
    display:none;
  }
  .sidebar-admin .toggle ion-icon.open,
  .sidebar-admin .toggle.active ion-icon.close{
    display:block;
  }
  .sidebar-admin .toggle ion-icon.close,
  .sidebar-admin  .toggle.active ion-icon.open{
    display:none;
  }


@media (max-width:767px) {
  .sidebar-admin   .navigation {
      /* top: 194px;
      bottom: 74%; */
      /* position: inherit; */
      margin-bottom: 50px;
      z-index: 9999;
      margin-left: -490px;
    }

    .sidebar-admin   .navigation ul {
      /* top: 1728px; */
      padding: 72px 0;
      position: absolute;
      margin-left: 125px;     
    }

    .sidebar-admin .toggle  {
    position: absolute;
    top: 80px;
    right: 15px;

    }

}

/* @media (min-width:767px) and (max-width:1023px) {

  .sidebar-admin   .navigation {
  
    position: inherit;
    margin-bottom: 50px;
  }
  .sidebar-admin   .navigation ul {
    
    padding: 75px 0;
    position: absolute;
    top: 1728px;
   
  }
  .sidebar-admin .toggle  {
    display: none;


  }
} */



.side-nav-container {
	background-color: #e03a56;
	width: 300px;
	height: 100vh;
	position: relative;
	color: #fff;

	transition: 0.4s;
}
.side-nav-container-NX {
	width: 85px;
}

.nav-upper,
.nav-heading,
.nav-menu,
.menu-item,
.nav-footer {
	/* border: 2px solid white; */
	display: grid;
}

.nav-heading {
	grid-template-columns: 2fr 1fr;
	grid-template-rows: 1fr;
	height: 75px;
}

.nav-brand {
	display: flex;
	color: #fff;
}
.nav-brand img {
	width: 40px;
	padding: 0 10px;
}

.hamburger {
	background: none;
	border: none;
	cursor: pointer;
	margin: auto;
}

.hamburger span {
	display: block;
	margin-top: 5px;
	background-color: #fff;
	border-radius: 15px;
	height: 5px;
	width: 35px;

	transition: 0.4s;
}

.hamburger:hover span {
	background-color: #fff;
}

.hamburger-in:hover span:nth-child(1) {
	width: 25px;
	transform: translateY(4px) rotate(-25deg);
}
.hamburger-in:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-in:hover span:nth-child(3) {
	width: 25px;
	transform: translateY(-4px) rotate(25deg);
}
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
/* ///////////////////// */
.hamburger-out {
	margin-left: 24px;
}
.hamburger-out:hover span:nth-child(1) {
	width: 25px;
	transform: translate(14px, 4px) rotate(-155deg);
}
.hamburger-out:hover span:nth-child(2) {
	width: 40px;
}
.hamburger-out:hover span:nth-child(3) {
	width: 25px;
	transform: translate(14px, -4px) rotate(155deg);
}

.nav-menu {
	grid-template-rows: repeat(7, 1fr);
	margin-top: 50px;
}

.menu-item {
	height: 57px;
	display: flex;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	margin: auto 20px;
	border-radius: 10px;
}

.menu-item-NX {
	margin: auto;
}
.menu-item:hover {
	background-color: #FFBF00;
}
.menu-item img {
	width: 30px;
	padding: 0 20px;
}

.nav-footer {
	width: 100%;
	height: 87px;
	position: absolute;
	bottom: 0;
	grid-template-rows: 1fr;
	grid-template-columns: 2fr 1fr;
}

.nav-details {
	display: flex;
}
.nav-details img {
	width: 50px;
	padding: 0 20px;
}

.nav-footer-user-name {
	font-size: 18px;
	font-weight: 900;
}

.nav-footer-user-position {
	margin-top: -15px;
	color: #fff;
}
.logout-icon {
	width: 30px;
	margin: auto;
	border-radius: 90px;
	padding: 20px;
	margin-left: 5px;
}
.logout-icon:hover {
	background-color: #fff;
}


@media (min-width:767px) and (max-width:1023px) {
  .sidebar-admin .navigation {
    position: inherit;
    margin-bottom: 50px;
    z-index: 9999;
    margin-left: -490px;
}
}

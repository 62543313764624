
.quiz-main {
    background-color: #e9e9e9;
}

.quiz-main .quiz-app {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px 0px;
        border-radius: 20px;
        padding: 20px 0;
        margin: 75px 0 150px 0;
}   


.quiz-main .quiz-data{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #e9e9e9;
    margin-top: 40px;
    width: 80%;
    margin: 40px auto 40px auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 5px 5px 15px 0px;
    padding: 25px 0 50px 0;
}

.quiz-main .quiz-data .timer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f43f5e;
    padding: 10px 20px;
    margin: 20px 0;
    color: white;
    border-radius: 10px;
}
.quiz-main .quiz-data .timer img{
  width: 35px;
    margin-left: 5px;
}

.quiz-main .quiz-data .timer span:first-child {
    font-weight: 500;
    font-size: 18px;
}

.quiz-main .quiz-data .timer span:last-child {
    font-size: 25px;
    font-size: 500;
    font-weight: 600;
}

@media (max-width:767px) {
        
.quiz-main .quiz-data {
    width: 95%;
}
}

.quiz-main .answers {
    flex-direction: column;
    margin-left: 50px;
    transition: 0.3s
}


input[type="radio"] {
    margin-right: 20px;
    transition: 0.3s
}

.form-quiz img {
    width: 10%;
}

.question-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    transition: 0.3s;
    width: 100%;
    flex-wrap: wrap;
  
  }
  
  .question-tab {
    background-color: #aaa;
    box-shadow: rgba(0, 0, 0, 0.010) 5px 5px 15px 0px;
    color: #fff;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 13px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 5px;
    margin-bottom: 10px;
    /* pointer-events: none; */
  }
  .question-tab:hover {
      transform:scale(1.09);
  }

  .quiz-main .quiz-info {
    text-align: right;
    width: 43%;
  }

  @media (max-width:767px) {
    .quiz-main .quiz-info {
      width: 80%;
    }
  }

  .quiz-main .quiz-info .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
  }

  .quiz-main .quiz-info p{
    font-size: 19px;
    font-weight: 600;
    color: #333;
    margin-bottom: 25px;
  }

  .quiz-main .quiz-info .content span {
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 8px;
  }
  .quiz-main .quiz-info .content span.length {
     background-color: #e02a24;
  }
  .quiz-main .quiz-info .content span.solved {
     background-color: #3b82f6;
  }
  .quiz-main .quiz-info .content span.unsolved {
     background-color: #FFBF00;
  }

  

  .quiz-main .quiz-info p span.solved {
    background-color: #3b82f6;
  }

  .quiz-main .quiz-info p span.unsolved {
    background-color: #FFBF00;
  }
  
  /* .question-tab.active {
    background-color: #3b82f6;
    transition: 0.3s
  } */

  .question-tab.active,
.question-tab.answered {
  background-color: #3b82f6;
  transition: 0.3s; /* لون الخلفية عندما يتم تحديد السؤال */
  z-index: 99999;
}

  .question-tab.answered {
 background-color: #f43f5e; 
  }

 .quiz-main  label.answered {
    background-color: #3b82f6;
  
  }

  .quiz-main .quiz-control {
    margin: 20px 0 30px 0;
  }

  .quiz-main .quiz-control button{
    border: none;
    padding: 10px 30px;
    border: 2px solid #f43f5e;
    background-color: #f43f5e;
    margin: 0 15px;
    transition: 0.3s;
    color: #fff;
    border-radius: 10px;
  }

  .quiz-main .quiz-control button:hover {
    background-color: #e9e9e9;
    color: #000;
  }

  .quiz-main .quiz-finish button{
        border: none;
        padding: 10px 100px;
        background-color: #3b82f6;
        border: 2px solid #3b82f6;
        color: white;
        border-radius: 10px;
        transition: 0.3s;
  }

  .quiz-main .quiz-finish button:hover {
    background-color: #e9e9e9;
    color: #000;
    
  }

  .quiz-main .exam {
    direction: rtl;
    padding: 10px 30px;
  }

  .quiz-main .exam .question {
      font-size: 35px;
      font-weight: 600;
  }

  .quiz-main .exam .answers label{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

    .quiz-main .exam .answers   input[type="checkbox"] {
      margin-left: 10px;

      width: 18px;
      height: 14px;
    }

    .quiz-main img.img-question {
      width: 100%;
      margin-bottom: 30px;
    }

    .quiz-main img.icon-question {
      width: 60px;
      margin-bottom: 16px;
      margin-left: 11px;
    }

    .quiz-main .start {
      border: none;
      background-color: #e03a56;
      color: #fff;
      width: 50%;
      padding: 20px 0;
      border-radius: 20px;
      font-size: 18px;
      font-weight: 500;
      transition: 0.3s;
      border: 2px solid #e03a56;
      box-shadow: rgba(0, 0, 0, 0.05) 5px 5px 15px 0px;
      margin: 177px 0;

    }

    @media (max-width: 767px) {
      .quiz-main .start {
        width: 80%;
      }
    }

    .quiz-main .start:hover {
        background-color: #e9e9e9;
        color: #000;

    }

    @media (max-width:767px) {
     .quiz-main img  {
      width: 100%;
     } 
    }

    @media (min-width:768px) and (max-width: 1024px)  {
      .quiz-main img  {
        width: 100%;
       }
    }

    .exam .arrange {
      text-align: left;
      font-size: 1.6rem;
      font-weight: 600;
      display: block;
        }

        .exam .arrange span {
          color: #f43f5e;
        }


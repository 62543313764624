.allstudent-admin  {
    /* min-height: 100vh; */
    background-color: #e6e6e6;
   width: 100%;
   padding-top: 70px;
}

.allstudent-admin .sidebar {
    width: 300px;
    margin-left: auto;
    background-color: #e6e6e6;
}



@media (max-width:767px) {
    .allstudent-admin{
        padding: 70px 0;
        width: 100%;

    }
    .allstudent-admin .search-table {
        width: 100%;
    }

    .sidebar {
            width: 0;
    }
    /* .homepage-admin .card .box .content h2 {
    
        transform: translateY(20%);
        width: 100%;
        top: -50%;
    } */
}

/* .allstudent-admin .sc-dkrFOg.iSAVrt.rdt_TableHeadRow  {
    padding: 10px 10px;
    border-top: 3px solid #e03a56;
    border-bottom: 3px solid #e03a56;
    font-size: 1rem;
    font-weight: 600;
    margin-top: -11px;
} */

.table-grid {
    background-color: #fff;
    border-radius: 20px;
}
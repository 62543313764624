.App {
  font-family: 'Lemonada', cursive;
  font-family: 'Almarai', sans-serif;
  /* min-height: 1000vh; */
  /* background-color: #eee; */
  /* z-index: 0; */
  /* position: relative; */
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.scroller {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6px;
  /* background-color: rgb(244, 62, 92); */
  background-color: #dbdbdb;
  z-index: 99999;
  /* opacity: 0.2; */
}

.scroller-linear {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  /* height: 500px; */
  /* background-color: rgb(244, 62, 92); */
  background-color: rgb(219 219 219);
  z-index: 99999;
  /* opacity: 0.2; */
}


.navbar .dropdown-menu.show {
    z-index: 999999;
}

.toprofile {
  text-decoration: none;
  width: 100%;
}
.navbar .wallet {
  width: 50px;
  margin-right: 20px;
}

.fa-user-tie  {
font-size: 30px;
background-color: #e3e3e3;
border-radius: 50%;
padding: 10px;
width: 100%;
height: 70%;

}
.lord-icon {
  font-size: 305px;
}
.user_img {
  width: 45px;
}

.exit_prof {
  width: 22px;
}

.show.dropdown:focus {
  border: none;
 
}

button#dropdown-basic {
  border: none;
}

.dropdown-menu.show {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: -85px;
  background-color: #eee;
  padding-bottom: 0;
  padding-top: 0;
  z-index: 999999999;
}
.dropdown-menu.show a {
  color: #000;
  padding: 15px 10px 12px 17px;
}

img.wallet {
  width: 50px;
  margin-bottom: 5px;
}

@media (max-width:767px) {
  .dropdown-menu.show {
    margin-left: 0;
  }
}


a.dropdown-item {
  font-weight: 600;
  transition: 0.1s;
  padding: 12px 15px 12px 0px;
  text-align: right;
}
a.dropdown-item:hover {
  background-color: white;
}
a.dropdown-item:not(:last-child) {
  margin-bottom: 10px;
}

.dropdown-toggle::after {
  display: none;
}

.scrollerStyle {
  height: 6px;
  background-color: #f43f5e;
  /* width: 15%; */
  box-shadow:  inset 0 0 10px rgb(0 0 0 / 15%);
  z-index: 9999999;
}

/* .dark-mode {
  background-color: #333;
  color: #fff;
} */




@media (max-width:767px) {
  .scroller {
    top: 72px;
  }
  .main-title {
    /* padding: 15px 40px; */
    font-size: 1rem;
  }
}
.main-title {
  width: fit-content;
  border: 2px solid black;
  margin: 0 auto 80px;
  padding: 15px 40px;
  font-size: 1.4rem;
  position: relative;
  z-index: 1;
  font-weight: bold;
  text-align: center;
}

.main-title:hover {
  color: white;
  border-color:  white;
  transition-delay: 0.5s;
}



.main-title::before {
  border-radius: 50%;
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #3b82f6;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}

.main-title:hover::before {
z-index: -1;
animation: left-move 0.5s linear forwards;
}
.main-title:hover::after {
z-index: -1;
animation: right-move 0.5s linear forwards;
}

@keyframes left-move {
  50% {
      left: 0;
      width: 12px;
      height: 12px;
  }
  100% {
      left: 0;
      border-radius: 0;
      width: 50%;
      height: 100%;
  }
}

@keyframes right-move {
  50% {
      right: 0;
      width: 12px;
      height: 12px;
  }
  100% {
      right: 0;
      border-radius: 0;
      width: 50%;
      height: 100%;
  }
}

.main-title::after {

  border-radius: 50%;
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #3b82f6;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
} 

.navbar-link .navs-link{
  text-decoration: none;
  padding: 10px 30px;
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 40px;
  /* width: 140x; */
  width: fit-content;
  /* border: 1px solid #61dafb; */
} 

.navbar-link .navs-link span {
  position: relative;
  color: #61dafb;
  /* color: #fff; */
    font-size: 1rem;
    z-index: 1;
    font-weight: 400;
}

.navbar-link .navs-link span:nth-child(1) {
  color : #f43f5e;
}

.navbar-link .navs-link .liquid {
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  width: 200px;
  height: 200px;
  background-color: #61dafb;
  transition: 0.5s;
  box-shadow: inset 0 0 50px rgba(0 , 0 , 0 ,0.3);
}

.navbar-link .navs-link .liquid:nth-child(2) {
  background-color: #f43f5e;
}

.navbar-link .navs-link .liquid::after ,
.navbar-link .navs-link .liquid::before {
  content: '';
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50% , -75%);
  background-color: rgb(248,249,250);
} 

.navbar-link .navs-link .liquid::before {
  border-radius: 40%;
  /* background-color: rgba(20 , 20 , 20 , 1); */
  animation: animate 5s linear infinite;
}

.navbar-link .navs-link .liquid::after {
  border-radius: 45%;
  /* background-color: rgba(20 , 20 , 20 , 1); */
  animation: animate 10s linear infinite;
}
.navbar-link .navs-link:hover .liquid {
  top: -120px;
}

.navbar-link .navs-link:hover span {
  color: #fff;
}

nav.navbar.pt-3.pb-3.navbar.navbar-expand-lg.navbar-light.bg-light.sticky-top {
  z-index: 99999999999;
}

@keyframes animate {
    0% {
      transform: translate(-50% , -75%) rotate(0deg)
    }
    100% {
      transform: translate(-50% , -75%) rotate(360deg)
    }
}




.navbar h1 {
  font-weight: bolder;
  text-transform: uppercase;
  /* font-size: 0.9rem; */
  font-size: 1.9rem;
  margin-bottom: 0;
  -webkit-text-stroke-width: 2px;
  letter-spacing: 1px;
}

.navbar h1 span {
color: #f43f5e;
  text-align: center;
}

@media (max-width:767px) {
  .navbar-link .navs-link:nth-child(1) {
    margin-bottom: 30px;
  
    /* width: ; */
  }
  .navbar-link .navs-link:nth-child(2) {
    padding: 10px 37px;
  }
  
.navbar-link {
  margin-top: 40px;
}
}

@media (min-width:767px) and (max-width:1023px) {
  .navbar-link .navs-link:nth-child(1) {
    margin-bottom: 20px;
    /* width: ; */
  }
  
.navbar-link {
  margin-top: 30px;
}
.scroller {
  bottom: 0;}
}






.footer {
    /* background-color: #5ccfee; */
    background-color: wheat;
    /* min-height: 45vh; */
    position: relative;
    width: 100%;
    bottom: 0;
}



.wrapper .button{
  display: inline-block;
  height: 50px;
  width: 50px;
  float: left;
  margin: 0 5px;
  overflow: hidden;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
  transition: all 0.3s ease-out;
}
.wrapper .button:hover{
  width: 200px;
}
.wrapper .button .icon{
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 50px;
  transition: all 0.3s ease-out;
}
.wrapper .button:nth-child(1):hover .icon{
  background: #4267B2;
}
.wrapper .button:nth-child(2):hover .icon{
  background: #1DA1F2;
}
.wrapper .button:nth-child(3):hover .icon{
  background: #E1306C;
}
.wrapper .button:nth-child(4):hover .icon{
  background: #ff0000;
}
.wrapper .button .icon i{
  font-size: 25px;
  line-height: 50px;
  transition: all 0.3s ease-out;
}
.wrapper .button:hover .icon i{
  color: #fff;
}
.wrapper .button span{
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  margin-left: 25px;
  transition: all 0.3s ease-out;
  text-decoration: none;
}
.wrapper .button:nth-child(1) span{
  color: #4267B2;
  text-decoration: none;
}
.wrapper .button:nth-child(2) span{
  color: #1DA1F2;
}
.wrapper .button:nth-child(3) span{
  color: #E1306C;
}
.wrapper .button:nth-child(4) span{
  color: #ff0000;
}


@media (max-width:767px) {
  .wrapper .button span{
    margin-left: 10px;
  }
}
.footer h1 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 35px;
}

.footer .develop span {
    color: #e02a24;
    font-size: 1.3rem;
    font-weight: 600;
}

.footer .develop a {
  margin: 0 5px;
  font-size: 1.3rem;
  text-decoration: none;
  color: black;
  font-weight: 800;
  transition: 0.3s;
}

.footer .develop a:nth-child(3):hover {
  color: #777;
  padding-right: 10px;
}

.footer .develop a:nth-child(2):hover {
  color: #777;
  padding-left: 10px;
}

.wrapper a {
text-decoration: none;
}

@media (max-width:767px) {
  .footer h1 {
    font-size: 1.3rem;
  }
}






.quiz {
  background-color: #e6e6e6;
  min-height: 80vh;
  padding-top: 50px;
}


.quiz-page h5{
  text-align: center;
}

.quiz-page .option-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
}

.quiz-page .option-container .option {
  background-color: green;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
}

.quiz-page  .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quiz-page  .button-container button{
  border: none;
  color: #fff;
  margin:  30px 100px 0 100px;
  padding: 10px 30px;
}

.quiz-page  .button-container button:first-child {
  background-color: black;
}

.quiz-page  .button-container button:last-child {
  background-color: red;
}

.quiz-page  .button-container button:nth-child(2) {
  background-color: blue;
}

.quiz-page .life-line-container {
  display: flex;
  justify-content:center;
  align-items:center;
}

.quiz-page .life-line-container span {
  margin: 0 200px;
  background-color: #fff;
  padding: 10px 30px;
}

.disable {
    pointer-events: none ;
    background-color: #ccc !important;
    box-shadow: none !important;
    opacity: 0.7 !important;
}



 /* .progress {
	background-color: #d8d8d8;
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 30px;
	width: 300px;
}

.progress-done {
	background: linear-gradient(to left, #F2709C, #FF9472);
	box-shadow: 0 3px 3px -5px #F2709C, 0 2px 5px #F2709C;
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
} */

/* #light {
  background-color: #e6e6e6;
}

#dark { 
   background-color: #333333;
} */


 .error {
  color: red;
  font-weight: 500;
  font-size:16px;
}

.no-courses {
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

}

    .no-courses  h2 {
      text-shadow: 0px 0px 40px #e02a24;

      color: #d53752;
  font-size: 55px;
  font-weight: 700;
    }

    .youtube-share-button {
    display: none;
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #e9e9e9;
  text-align: center;
}

.not-found span {
  color: #e02a24;
  font-weight: 600;
  text-align: center;

}
.number-phone {
  display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    background-color: #e9e9e9;
    flex-direction: column;
    width: 100%;
}

.number-phone h2 {
  color: #000;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 1px;
}


.scroll-top-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f43f5e;
  color: #fff;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
  z-index: 99999999;
}

.scroll-top-button.is-visible {
  opacity: 1;
  visibility: visible;
}

.navbaR .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.navbaR {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  background-color: #f8f9fa !important;
  /* color: white; */
  padding: 20px 20px;
  position: relative;
  position: sticky;
  top: 0;
  z-index: 999999999999999;
}



.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.toggle-button {
  display: none;
}

.navbaR .navbar-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  

  
}

.navbaR .navbar-links li {
  padding: 0 10px;
}

.navbaR .navbar-links li a {
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .toggle-button {
    display: block;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .toggle-button .bar {
    display: block;
    width: 35px;
    height: 3px;
    background-color: black;
    margin: 7px 0;
    transition: transform 0.3s;
  }

  .navbaR .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 77px;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px;
    border-radius: 0px 0px 40px 40px;
    
  
 
  }
  .navbaR .navs-link {
    margin-bottom: 20px;
    padding: 0 50px;

  }
  a.navs-link {
  
    padding: 0 50px;

  }

  .navbaR .navbar-links li {
    padding: 10px 0;
  }

  .navbaR .navbar-links li a {
    display: block;
    text-align: center;
  }

  .navbaR .navbar-links.active {
    display: flex;
   
  }


  .toggle-button.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-7px, 7px);
  }

  .toggle-button.active .bar:nth-child(2) {
    opacity: 0;
  }

  .toggle-button.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-7px, -7px);
  }
}


.navbaR h1 {
  font-weight: bolder;
  text-transform: uppercase;
  /* font-size: 0.9rem; */
  font-size: 1.9rem;
  margin-bottom: 0;
  -webkit-text-stroke-width: 2px;
  letter-spacing: 1px;
  color: #000;
}

.navbaR h1 span {
color: #f43f5e;
  text-align: center;
}


.navbaR .navs-link{
  text-decoration: none;
  padding: 10px 30px;
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 40px;
  /* width: 140x; */
  width: fit-content;
  -webkit-border-radius: 40px; 
  -moz-border-radius: 40px
  
  /* border: 1px solid #61dafb; */
} 

.navbaR .navs-link span {
  position: relative;
  color: #61dafb;
  /* color: #fff; */
    font-size: 1rem;
    z-index: 1;
    font-weight: 400;
}

.navbaR .navs-link span:nth-child(1) {
  color : #f43f5e;
}

.navbaR .navs-link .liquid {
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  width: 200px;
  height: 200px;
  background-color: #61dafb;
  transition: 0.5s;
  box-shadow: inset 0 0 50px rgba(0 , 0 , 0 ,0.3);
}

.navbaR .navs-link .liquid:nth-child(2) {
  background-color: #f43f5e;
}

.navbaR .navs-link .liquid::after ,
.navbaR .navs-link .liquid::before {
  content: '';
  width: 200%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50% , -75%);
  background-color: rgb(248,249,250);
} 

.navbaR .navs-link .liquid::before {
  border-radius: 40%;
  /* background-color: rgba(20 , 20 , 20 , 1); */
  -webkit-animation: animate 5s linear infinite; /* Safari 4.0 - 8.0 */
  animation: animate 5s linear infinite;
}

.navbaR .navs-link .liquid::after {
  border-radius: 45%;
  /* background-color: rgba(20 , 20 , 20 , 1); */
  -webkit-animation: animate 10s linear infinite; /* Safari 4.0 - 8.0 */
  animation: animate 10s linear infinite;
}

.navbaR .navs-link:hover .liquid {
  top: -120px;
}

.navbaR .navs-link:hover span {
  color: #fff;
}

@media only screen and (max-device-width: 480px) {
  .navbaR .navs-link{
    text-decoration: none;
    padding: 10px 30px;
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 40px;
    /* width: 140x; */
    width: fit-content;
    /* border: 1px solid #61dafb; */
  } 
  
}




@keyframes animate {
    0% {
      transform: translate(-50% , -75%) rotate(0deg)
    }
    100% {
      transform: translate(-50% , -75%) rotate(360deg)
    }
}


@-webkit-keyframes animate { /* Safari 4.0 - 8.0 */
  0% {
    -webkit-transform: translate(-50%, -75%) rotate(0deg);
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -75%) rotate(360deg);
    transform: translate(-50%, -75%) rotate(360deg);
  }
} 


.main-loading span {
  display: inline-block;
  animation: dot 1.5s ease-in-out infinite;
}

@keyframes dot {
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-10px);
  }
  100% {
      transform: translateY(0);
  }
}



